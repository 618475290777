<template>
  <div
    id="main-container"
    class="w-full"
    :style="{ backgroundImage: 'url(' + bgMain + ')' }"
  >
    <div class="sub-main-container flex justify-between items-center mx-auto">
      <div
        class="w-1/2 h-full flex flex-col items-center"
        style="
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        "
      >
        <div
          class="title-container flex items-center justify-center py-4 my-8 border-b-8 border-white"
        >
          <img id="logo-title" :src="logo" alt="Company Logo" />
          <h2 id="kortle-text" class="font-bold">ORTLE</h2>
        </div>

        <div class="floating-text-container w-4/6 items-center">
          <h1 class="floating-text text-4xl text-white p-4 h-full">
            {{ $t("intro.1") }}
            <span id="kortle-span">Kortle</span>{{ $t("intro.2") }}
            <span id="dev-span">{{ $t("intro.dev") }}</span
            >,{{ $t("intro.4") }}
            <span id="cyb-span">{{ $t("intro.cyber") }}</span
            >, {{ $t("intro.6") }}
            <span class="mtg-span">{{ $t("intro.montage") }} photo</span>
            {{ $t("intro.7") }}
            <span class="mtg-span"
              >{{ $t("intro.video") }}{{ $t("intro.edit") }}</span
            >, {{ $t("intro.8") }}
            <span id="com-span">{{ $t("intro.digit") }}</span
            >. {{ $t("intro.10") }}
          </h1>
        </div>
      </div>
      <div
        class="sub-intro-dessin w-1/2 flex items-center flex-col justify-center"
      >
        <img :src="mainIsometricLaptop" alt="" />
      </div>
    </div>
  </div>
  <NosServices></NosServices>
  <TechnologiesComponent></TechnologiesComponent>

  <SliderComponent
    :imageUrl1="vleventImage"
    :imageUrl2="audiImage"
    :imageUrl3="cabinetPsy"
  ></SliderComponent>
  <StatsCounter></StatsCounter>
  <NousContacter></NousContacter>
  <FooterComponent></FooterComponent>
</template>

<script>
// Components
import NosServices from "@/components/Home/NosServices.vue";
import NousContacter from "@/components/Home/NousContacter.vue";
//import ColoredText from "@/components/Home/ColoredText.vue";
import SliderComponent from "@/components/Home/SliderComponent.vue";
import TechnologiesComponent from "@/components/Home/TechnologiesComponent.vue";
import StatsCounter from "@/components/Home/StatsCounter.vue";

// Images
import mainIsometricLaptop from "@/assets/single-images/isometricScene.webp";
import vleventImage from "@/assets/single-images/vlevent.webp";
import audiImage from "@/assets/single-images/22360.webp";
import cabinetPsy from "@/assets/single-images/psy.webp";
import bgMain from "@/assets/single-images/bg-main.jpg";
import logo from "@/assets/logo/kortle-nobg.png";

export default {
  components: {
    NosServices,
    //ColoredText,
    NousContacter,
    SliderComponent,
    TechnologiesComponent,
    StatsCounter,
  },
  data() {
    return {
      mainIsometricLaptop,
      vleventImage,
      audiImage,
      cabinetPsy,
      bgMain,
      logo,
    };
  },
};
</script>

<style scoped>
body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000;
  overflow: hidden;
}

h2 {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 700;
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
}

#logo-title {
  height: 11vw;
}
.sub-main-container {
  width: 91.66%;
}
.title-container {
  width: 83%;
}

.sub-intro-dessin {
  height: 100%;
}

#main-container {
  padding-top: 133px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60rem;
}

#kortle-text {
  font-size: 8vw;
}
#kortle-span {
  padding-inline: 5px;
  border-radius: 10px;
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
}
#cyb-span,
#com-span,
#dev-span,
.mtg-span {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 700;
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
}

.floating-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  width: 100%;
  height: 47%;
  padding: 10px;
}
.floating-text {
  line-height: 45px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

@media all and (max-width: 1255px) and (min-width: 1150px) {
  .sub-main-container {
    width: 100%;
  }
}

@media all and (max-width: 1150px) {
  #main-container {
    height: 95rem;
  }
  .sub-main-container {
    flex-direction: column;
  }
  .sub-main-container > div {
    width: 100%;
  }
  #logo-title {
    height: 20vw;
  }
  #kortle-text {
    font-size: 17vw;
  }
}

@media all and (max-width: 830px) {
  #main-container {
    height: 96rem;
  }
}
@media all and (max-width: 535px) {
  h1 {
    font-size: 1.9rem;
  }
}
@media all and (max-width: 440px) {
  #main-container {
    height: 100rem;
  }
}
@media all and (max-width: 350px) {
  #main-container {
    height: 110rem;
  }
}
</style>
