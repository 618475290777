<template>
  <nav ref="nav" class="w-full bg-white mx-auto" id="nav-container">
    <h2 id="title" class="text-4xl pt-8 text-center" style="font-weight: bold">
      {{ $t("technologies.title") }}
    </h2>
    <!-- Timeline Shape -->
    <div class="flex img-container" style="padding-top: 0.5vw">
      <div class="w-1/4">
        <p
          ref="text1"
          class="bg-white rounded-xl text-2xl font-semibold"
          style="margin-bottom: 2.5vw"
        >
          Mobile
        </p>
        <img
          ref="timelineImage1"
          :src="technoApp"
          alt="Timeline Image"
          class="timeline-image"
        />
      </div>
      <div class="w-1/4 flex flex-col-reverse">
        <p class="bg-white rounded-xl text-2xl font-semibold" ref="text2">
          Web
        </p>
        <img
          ref="timelineImage2"
          :src="technoWeb"
          alt="Timeline Image"
          class="timeline-image middle-image"
          style="margin-bottom: 1.5vw"
        />
      </div>
      <div class="w-1/4">
        <p
          ref="text3"
          class="bg-white rounded-xl text-2xl font-semibold"
          style="margin-bottom: 2.5vw"
        >
          {{ $t("technologies.db") }}
        </p>
        <img
          ref="timelineImage3"
          :src="technoDb"
          alt="Timeline Image"
          class="timeline-image"
        />
      </div>
    </div>
    <svg viewBox="0 0 300 100" class="w-full mx-auto" style="height: 380px">
      <defs>
        <linearGradient id="path-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style="stop-color: rgba(124, 27, 241, 1); stop-opacity: 1"
          />
          <stop offset="100%" style="stop-color: #29aceb; stop-opacity: 1" />
        </linearGradient>
      </defs>
      <g transform="translate(4.95, 0)">
        <path
          id="timeline-path"
          d="M10 95 L10 15 Q10 5 20 5 L90 5 Q100 5 100 15 L100 85 Q100 95 110 95 L180 95 Q190 95 190 85 L190 15 Q190 5 200 5 L270 5 Q280 5 280 15 L280 95"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
        <path
          id="filled-path"
          d="M10 95 L10 15 Q10 5 20 5 L90 5 Q100 5 100 15 L100 85 Q100 95 110 95 L180 95 Q190 95 190 85 L190 15 Q190 5 200 5 L270 5 Q280 5 280 15 L280 95"
          fill="none"
          stroke="url(#path-gradient)"
          stroke-width="2"
          stroke-dasharray="0"
          stroke-dashoffset="0"
        />
      </g>
    </svg>
  </nav>
</template>

<script>
import technoApp from "@/assets/homemade images/apps.png";
import technoWeb from "@/assets/homemade images/web.png";
import technoDb from "@/assets/homemade images/db.png";

export default {
  name: "TechnologiesComponent",
  data() {
    return {
      pathLength: 0,
      isAnimating: false,
      technoApp,
      technoWeb,
      technoDb,
    };
  },
  methods: {
    startAnimation() {
      const path = document.getElementById("filled-path");
      this.pathLength = path.getTotalLength();
      path.style.strokeDasharray = this.pathLength;
      path.style.strokeDashoffset = this.pathLength;
      this.isAnimating = true;
      requestAnimationFrame(() => {
        path.style.transition = "stroke-dashoffset 0.5s ease-out";
      });
    },
    stopAnimation() {
      const path = document.getElementById("filled-path");
      path.style.transition = "none";
      path.style.strokeDashoffset = 0;
      this.isAnimating = false;
    },
    handleScroll() {
      if (this.isAnimating) {
        const path = document.getElementById("filled-path");
        const scrollTop = window.scrollY;
        const scrollHeight = document.documentElement.scrollHeight;
        const clientHeight = window.innerHeight;
        const navRect = this.$refs.nav.getBoundingClientRect();
        const navTopOffset = navRect.top + scrollTop;
        const maxScroll = scrollHeight - clientHeight;
        const isPhone = window.innerWidth <= 900;
        const isLandscape = window.matchMedia(
          "(orientation: landscape)"
        ).matches;

        const additionalOffset = isPhone ? (isLandscape ? -40 : -80) : 100;
        const speed = isPhone ? (isLandscape ? 7.15 : 7) : 6.5;

        // Calculate scroll progress relative to the component's position
        const scrollProgress = Math.max(
          0,
          Math.min(
            this.pathLength,
            ((scrollTop - navTopOffset + clientHeight / 2 + additionalOffset) /
              maxScroll) *
              this.pathLength *
              speed
          )
        );
        // Apply stroke-dashoffset
        path.style.strokeDashoffset = Math.max(
          0,
          this.pathLength - scrollProgress
        );

        // Show or hide the images and texts based on the path length
        const imageOneLength = this.pathLength * (2 / 7);
        const imageTwoLength = this.pathLength * (4 / 7);
        const imageThreeLength = this.pathLength * (6 / 7);
        const currentLength =
          this.pathLength - parseFloat(path.style.strokeDashoffset);

        if (currentLength >= imageOneLength) {
          this.showImage(this.$refs.timelineImage1);
          this.showText(this.$refs.text1, false);
        } else {
          this.hideImage(this.$refs.timelineImage1);
          this.hideText(this.$refs.text1, false);
        }

        if (currentLength >= imageTwoLength) {
          this.showImage(this.$refs.timelineImage2, true);
          this.showText(this.$refs.text2, true);
        } else {
          this.hideImage(this.$refs.timelineImage2, true);
          this.hideText(this.$refs.text2, true);
        }

        if (currentLength >= imageThreeLength) {
          this.showImage(this.$refs.timelineImage3);
          this.showText(this.$refs.text3, false);
        } else {
          this.hideImage(this.$refs.timelineImage3);
          this.hideText(this.$refs.text3, false);
        }
      }
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.startAnimation();
        }
      });
    },
    showImage(image) {
      image.style.opacity = 1;
      image.style.transform = "translateY(0)";
    },
    hideImage(image, isMiddle = false) {
      image.style.opacity = 0;
      image.style.transform = isMiddle
        ? "translateY(-20px)"
        : "translateY(20px)";
    },
    showText(text, isMiddle = false) {
      text.style.opacity = 1;
      text.style.transform = isMiddle ? "translateY(0)" : "translateY(0)";
    },
    hideText(text, isMiddle = false) {
      text.style.opacity = 0;
      text.style.transform = isMiddle
        ? "translateY(20px)"
        : "translateY(-20px)";
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });
    this.observer.observe(this.$refs.nav);

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.unobserve(this.$refs.nav);
    }
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
img {
  height: fit-content;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.middle-image {
  transform: translateY(-20px);
}
p {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.img-container {
  justify-content: space-around;
  height: 380px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 1030px;
}
.timeline-content {
  z-index: 1;
  background: white;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
}

#nav-container {
  height: 490px;
  position: relative;
}

@media all and (max-width: 1160px),
  (orientation: landscape) and (max-width: 1160px),
  (max-device-width: 1160px) {
  .img-container {
    flex-direction: column;
    width: 670px;
    height: 954px;
  }
  .img-container > div {
    align-items: center;
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .img-container > div:nth-child(1),
  .img-container > div:nth-child(3) {
    flex-direction: row-reverse;
  }
  .img-container > div:nth-child(2) {
    flex-direction: row;
  }
  h2 {
    padding-bottom: 2rem;
  }
  img {
    max-width: 36%;
  }
  p {
    padding: 8px;
    margin-bottom: 0 !important;
    width: 225px;
    box-shadow: 0px 0px 11px 5px rgba(0, 0, 0, 0.4);
  }
  svg {
    top: 290px;
    position: relative;
    transform: rotate(90deg) translateY(139%);
    width: 1050px;
    left: 50%;
    -ms-transform: translateX(-50%);
  }
  #nav-container {
    justify-content: space-evenly;
    height: 1130px;
  }
}

@media all and (max-width: 1160px) and (min-width: 1050px),
  (orientation: landscape) and (max-width: 1160px) and (min-width: 1050px),
  (max-device-width: 1160px) and (min-device-width: 1050px) {
  svg {
    transform: rotate(90deg) translateY(49vw);
  }
}

@media all and (max-width: 690px),
  (orientation: landscape) and (max-width: 690px),
  (max-device-width: 690px) {
  .img-container {
    width: 90%;
    justify-content: space-between;
    height: 910px;
  }

  p {
    width: 215px;
  }
  img {
    max-width: 100%;
    width: 190px;
  }
  svg {
    top: 310px;
  }

  .img-container > div {
    flex-direction: column !important;
    height: 280px;
    justify-content: space-between;
  }
}
@media all and (max-width: 370px),
  (orientation: landscape) and (max-width: 370px),
  (max-device-width: 370px) {
  .img-container {
    transform: scale(0.8);
    z-index: 1;
  }
  svg {
    transform: rotate(90deg) translateY(139%) scale(0.8);
    top: 300px;
  }
}
@media all and (max-width: 300px),
  (orientation: landscape) and (max-width: 300px) {
  .img-container {
    transform: scale(0.7);
    z-index: 1;
  }
  svg {
    transform: rotate(90deg) translateY(139%) scale(0.7);
    top: 295px;
  }
}
</style>
