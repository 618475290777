import { createI18n } from 'vue-i18n'
import fr from './locales/fr.json'
import en from './locales/en.json'

function loadLocaleMessages() {
    const locales = [{ fr: fr }, { en: en }]
    const messages = {}
    locales.forEach(lang => {
        const key = Object.keys(lang)
        messages[key] = lang[key]
    })
    return messages
}

const defaultLocale = localStorage.getItem('locale') || 'fr';

export default createI18n({
    locale: defaultLocale,
    fallbackLocale: 'fr',
    messages: loadLocaleMessages()
})
