<template>
  <nav
    :class="[
      'navbar p-4 flex justify-between items-center shadow-md',
      { 'navbar-shrink': isShrunk, 'navbar-hide': isHidden },
    ]"
    :style="{ opacity: opacity }"
  >
    <!-- Logo -->
    <div class="text-white text-xl font-bold flex items-center">
      <router-link to="/">
        <img :src="logo" alt="Company Logo" class="navbar-logo" />
      </router-link>
    </div>

    <!-- Desktop Menu -->
    <div class="hidden md:flex space-x-4">
      <router-link
        to="/services"
        class="navbar-item text-white px-3 py-2 rounded-md text-xl font-medium"
      >
        {{ $t("menus.1") }}
      </router-link>
      <router-link
        to="/contact"
        class="navbar-item text-white px-3 py-2 rounded-md text-xl font-medium"
      >
        {{ $t("menus.2") }}
      </router-link>
      <div class="locale-changer">
        <select v-model="$i18n.locale" @change="saveLocale">
          <option
            v-for="(label, locale) in translatedLanguages"
            :key="locale"
            :value="locale"
          >
            {{ getFlag(locale) }} {{ label }}
          </option>
        </select>
      </div>
    </div>

    <!-- Mobile Menu Button -->
    <button @click="toggleMenu" class="md:hidden text-white focus:outline-none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16m-7 6h7"
        />
      </svg>
    </button>

    <!-- Mobile Menu -->
    <transition name="fade-slide">
      <div
        v-if="isMenuOpen"
        ref="mobileMenu"
        class="fixed top-24 right-2.5 w-1/2 bg-gray-800 text-white pb-3.5 menu-mobile"
      >
        <div class="flex flex-col items-center pt-5 space-y-6">
          <router-link to="/" class="text-xl font-medium" @click="toggleMenu">
            {{ $t("menus.home") }}
          </router-link>
          <router-link
            to="/services"
            class="text-xl font-medium"
            @click="toggleMenu"
          >
            {{ $t("menus.1") }}
          </router-link>
          <router-link
            to="/contact"
            class="text-xl font-medium"
            @click="toggleMenu"
          >
            {{ $t("menus.2") }}
          </router-link>
          <div class="locale-changer">
            <select v-model="$i18n.locale" @change="saveLocale">
              <option
                v-for="(label, locale) in translatedLanguages"
                :key="locale"
                :value="locale"
              >
                {{ getFlag(locale) }} &nbsp; {{ label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import logo from "@/assets/logo/kortle-nobg.png";

export default {
  name: "NavBarComponent",
  data() {
    return {
      logo,
      isShrunk: false,
      isHidden: false,
      lastScrollTop: 0,
      opacity: 1,
      isMenuOpen: false,
    };
  },
  computed: {
    translatedLanguages() {
      const locales = this.$i18n.availableLocales;
      const translations = {};

      locales.forEach((locale) => {
        translations[locale] = this.$t(`languages.${locale}`);
      });

      return translations;
    },
  },
  methods: {
    handleScroll() {
      const currentScrollTop = window.scrollY;
      const scrollDelta = currentScrollTop - this.lastScrollTop;

      if (currentScrollTop > 50) {
        this.isShrunk = true;
        if (scrollDelta > 0) {
          this.opacity = Math.max(
            0,
            this.opacity - Math.abs(scrollDelta) / 500
          );
          this.isHidden = this.opacity <= 0;
        } else {
          this.isHidden = false;
          this.opacity = Math.min(
            1,
            this.opacity + Math.abs(scrollDelta) / 500
          );
        }
      } else {
        this.isShrunk = false;
        this.opacity = 1;
        this.isHidden = false;
      }
      this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    },
    saveLocale() {
      localStorage.setItem("locale", this.$i18n.locale);
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleClickOutside(event) {
      // Check if click is outside the mobile menu and button
      if (
        this.isMenuOpen &&
        !this.$refs.mobileMenu.contains(event.target) &&
        !event.target.closest("button")
      ) {
        this.toggleMenu();
      }
    },
    getFlag(locale) {
      const flags = {
        en: "🇬🇧",
        fr: "🇫🇷",
      };

      // Vérifier si l'utilisateur est sur Chrome ou Edge
      const userAgent = navigator.userAgent;
      const isChrome = /Chrome/.test(userAgent) && !/Edge/.test(userAgent);
      const isEdge = /Edg/.test(userAgent);

      // Ne rien retourner si c'est Chrome ou Edge
      if (isChrome || isEdge) {
        return "";
      }
      return flags[locale] || "";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("touchstart", this.handleClickOutside);

    const savedLocale = localStorage.getItem("locale");
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("touchstart", this.handleClickOutside);
  },
};
</script>

<style scoped>
/* Import de la police Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

img {
  max-height: 100px;
  max-width: 100%;
  min-height: 50px;
  min-width: 50px;
  height: auto;
  width: auto;
}

.navbar-logo {
  transition: transform 0.3s ease;
}

.navbar-logo:hover {
  transform: scale(1.1);
}

.navbar {
  transition: all 0.5s ease, opacity 0.5s ease;
}

.navbar-shrink {
  padding: 0.5rem;
  background-color: rgba(99, 22, 138, 0.19);
}

.navbar-hide {
  display: none;
}

.navbar-shrink img {
  max-height: 60px;
}

.navbar-item {
  font-family: "Poppins", sans-serif;
  transition: all 0.3s ease;
  align-content: center;
}

.navbar-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #2799ec;
  transform: scale(1.1);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Ombre portée douce */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Ombre portée pour l'élément */
}

.menus-container {
  display: flex;
  align-items: center;
}

/* Styles pour le sélecteur de langue */
.locale-changer select {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  outline: none;
}

/* Style de l'option sélectionnée */
.locale-changer option {
  font-family: "Poppins", sans-serif;
}

/* Animation pour le menu mobile */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.menu-mobile {
  transform: translateX(0);
}
</style>
