<template>
  <h2 class="absolute mx-auto pt-8 text-white text-4xl" id="title">
    <span id="projects-title" class="px-4 w-full text-center">{{
      $t("projects.title")
    }}</span>
  </h2>
  <div
    id="main-container"
    class="flex justify-center flex-col relative overflow-hidden"
    style="background: #000"
  >
    <img
      :src="imageUrl2"
      class="w-full absolute bg"
      style="filter: blur(10px) brightness(0.5); opacity: 0"
    />
    <img
      :src="imageUrl1"
      class="w-full bg"
      style="filter: blur(10px) brightness(0.5)"
    />
    <img
      :src="imageUrl3"
      class="w-full absolute bg"
      style="filter: blur(10px) brightness(0.5); opacity: 0"
    />
    <div
      class="absolute left-0 right-0 flex mx-auto justify-around w-full"
      style="top: 18%"
    >
      <Card
        v-for="(card, index) in cards"
        :key="index"
        :title="card.title"
        :description="card.description"
        :imageUrl="card.imageUrl"
        :isCardCentered="card.isCardCentered"
        :class="card.className"
        :redirectTo="card.redirectTo"
        ref="cardComponents"
      >
      </Card>
    </div>
    <button @click="moveRight" class="arrow left-arrow">
      <div class="arrow-icon" style="transform: rotate(225deg)"></div>
    </button>
    <button @click="moveLeft" class="arrow right-arrow">
      <div class="arrow-icon" style="transform: rotate(45deg)"></div>
    </button>
  </div>
</template>

<script>
import Card from "@/components/Home/CardComponent.vue";

export default {
  components: {
    Card,
  },
  props: {
    imageUrl1: String,
    imageUrl2: String,
    imageUrl3: String,
  },
  data() {
    return {
      cards: [
        {
          title: this.$t("projects.kpedia.title"),
          description: this.$t("projects.kpedia.description"),
          imageUrl: this.imageUrl2,
          isCardCentered: false,
          className: "card-left",
          redirectTo: "http://kpedia.fr/",
        },
        {
          title: this.$t("projects.vlevents.title"),
          description: this.$t("projects.vlevents.description"),
          imageUrl: this.imageUrl1,
          isCardCentered: true,
          className: "card-center",
          redirectTo: "https://vlevents.fr/",
        },
        {
          title: this.$t("projects.leCabPsy.title"),
          description: this.$t("projects.leCabPsy.description"),
          imageUrl: this.imageUrl3,
          isCardCentered: false,
          className: "card-right",
          redirectTo: "https://le-cab-psy.fr/",
        },
      ],
      cardsDom: document.getElementsByClassName("z-50"),
      imagesBg: document.getElementsByClassName("bg"),
      centeredCardIndex: 1,
      isMovingLeft: false,
      isMovingRight: false,
      previousBackgroundImage: null,
      windowWidth: window.innerWidth,
      previousWindowWidth: window.innerWidth,
    };
  },
  computed: {
    backgroundImage() {
      return this.cards[this.centeredCardIndex].imageUrl;
    },
  },
  watch: {
    cardsDom(newValue, oldValue) {
      const oldArray = Array.from(oldValue);
      const newArray = Array.from(newValue);

      if (newArray[2] === oldArray[0]) {
        this.isMovingLeft = true;
        this.isMovingRight = false;
      } else if (newArray[0] === oldArray[2]) {
        this.isMovingLeft = false;
        this.isMovingRight = true;
      }
      console.log(this.centeredCardIndex);
      this.applyBackgroundTransition();
    },
    backgroundImage(newImage, oldImage) {
      this.previousBackgroundImage = oldImage;
    },
  },
  methods: {
    applyBackgroundTransition() {
      this.imagesBg = Array.from(this.imagesBg); // Convert to Array if needed
      this.imagesBg.forEach((image, index) => {
        image.style.opacity = index === this.centeredCardIndex ? "1" : "0";
        image.style.transition = "opacity 1.5s ease-in-out";
      });
    },

    moveLeft() {
      if (this.windowWidth < 1030) {
        this.updateCardsVisibility("left");
      }
      this.cardsDom[0].style.transform =
        "translateY(-1%) translateX(345px) rotateX(20deg) rotateY(-20deg)";
      this.cardsDom[0].style.zIndex = "50";
      this.cardsDom[1].style.transform =
        "translateX(-335px) rotateX(-20deg) rotateY(-30deg) translateY(-1vw)";
      this.cardsDom[1].style.zIndex = "51";
      this.cardsDom[2].style.transform = "rotateY(0deg) translateX(0%)";
      this.cardsDom[2].style.zIndex = "52";
      this.imagesBg[1].style.opacity = "0";
      this.imagesBg[2].style.opacity = "1";
      this.cardsDom = Array.from(this.cardsDom);
      this.cardsDom.push(this.cardsDom.shift());
      this.updateCenteredCardIndex("left");
    },

    moveRight() {
      if (this.windowWidth < 1030) {
        this.updateCardsVisibility("right");
      }

      this.cardsDom[0].style.transform = "rotateY(0deg) translateX(0%)";
      this.cardsDom[0].style.zIndex = "52";
      this.cardsDom[1].style.transform =
        "translateY(-1%) translateX(345px) rotateX(20deg) rotateY(-20deg)";
      this.cardsDom[1].style.zIndex = "51";
      this.cardsDom[2].style.transform =
        "translateX(-335px) rotateX(-20deg) rotateY(-30deg) translateY(-1vw)";
      this.cardsDom[2].style.zIndex = "50";
      this.imagesBg[1].style.opacity = "0";
      this.imagesBg[2].style.opacity = "1";
      this.cardsDom = Array.from(this.cardsDom);
      this.cardsDom.unshift(this.cardsDom.pop());
      this.updateCenteredCardIndex("right");
    },

    updateCardsVisibility(direction) {
      if (direction == "left") {
        this.cardsDom.forEach((card, index) => {
          card.style.opacity = index === 2 ? "1" : "0";
        });
      } else {
        this.cardsDom.forEach((card, index) => {
          card.style.opacity = index === 0 ? "1" : "0";
        });
      }
    },

    updateCenteredCardIndex(direction) {
      let centeredIndex = this.cards.findIndex((card) => card.isCardCentered);
      this.cards.forEach((card) => (card.isCardCentered = false));

      if (direction === "left") {
        this.centeredCardIndex = (centeredIndex + 1) % this.cards.length;
      } else if (direction === "right") {
        this.centeredCardIndex =
          (centeredIndex - 1 + this.cards.length) % this.cards.length;
      }

      this.cards[this.centeredCardIndex].isCardCentered = true;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
      if (
        (this.previousWindowWidth < 1030 && this.windowWidth >= 1030) ||
        (this.previousWindowWidth >= 1030 && this.windowWidth < 1030) ||
        (this.previousWindowWidth == this.windowWidth &&
          this.windowWidth < 1030)
      ) {
        if (this.windowWidth >= 1030) {
          this.cardsDom = Array.from(this.cardsDom);
          this.cardsDom.forEach((card) => {
            card.style.opacity = "1";
          });
          this.imagesBg = Array.from(this.imagesBg);
          this.imagesBg.forEach((image) => {
            image.style.opacity = "1";
            image.style.objectFit = "";
          });
        } else {
          this.cardsDom = Array.from(this.cardsDom);
          this.cardsDom.forEach((card, index) => {
            if (index == 1) {
              card.style.opacity = "1";
            } else {
              card.style.opacity = "0";
            }
          });
          this.imagesBg = Array.from(this.imagesBg);
          this.imagesBg.forEach((image) => {
            image.style.opacity = "0";
            image.style.objectFit = "cover";
          });
        }

        this.removeTransitions();
        setTimeout(() => {
          this.addTransitions();
        }, 500);
      }
      this.previousWindowWidth = this.windowWidth;
    },
    removeTransitions() {
      this.cardsDom.forEach((card) => {
        card.style.transition = "none";
      });
      this.imagesBg.forEach((image) => {
        image.style.transition = "none";
      });
      this.cardsDom.forEach((card) => {
        const textContent = card.querySelector(".text-content");
        if (textContent) {
          textContent.style.transition = "none";
        }
        const title = textContent.querySelector(".title");
        const description = textContent.querySelector(".description");
        if (title) {
          title.style.transition = "none";
        }
        if (description) {
          description.style.transition = "none";
        }
      });
    },
    addTransitions() {
      this.cardsDom.forEach((card) => {
        card.style.transition =
          "transform 1.5s ease-in-out, opacity 1.5s ease-in-out";
      });
      this.imagesBg.forEach((image) => {
        image.style.transition =
          "opacity 1.5s ease-in-out, transform 1.5s ease-in-out";
      });
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
#main-container {
  height: 790px;
}
#title {
  left: 0;
  right: 0;
  width: 100%;
  z-index: 51;
}

#projects-title {
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
}
.bg {
  height: 100%;
}
.left-0,
.right-0 {
  z-index: 55;
}

.card-left {
  z-index: 1;
  transform: translateX(-335px) rotateX(-20deg) rotateY(-30deg) translateY(-1vw);
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
}

.card-center {
  transform: rotateY(0deg) translateX(0%);
  z-index: 2;
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
}

.card-right {
  z-index: 1;
  transform: translateY(-1%) translateX(345px) rotateX(20deg) rotateY(-20deg);
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
}

img {
  height: 40vw;
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
  opacity: 1;
}

.fade-out-left {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-out-right {
  opacity: 0;
  transform: translateX(100%);
}

.fade-in-left {
  opacity: 1;
  transform: translateX(0);
}

.fade-in-right {
  opacity: 1;
  transform: translateX(0);
}
.bg {
  transition-duration: 1.5s;
  filter: blur(10px) brightness(0.5);
}
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  color: white;
  font-size: 2rem;
  padding: 1rem;
  cursor: pointer;
  z-index: 10;
}

.left-arrow {
  left: 30%;
  z-index: 55;
}

.right-arrow {
  right: 30%;
  z-index: 55;
}

.arrow-icon {
  height: 60px;
  filter: none;
}

div.arrow-icon {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
}

div.arrow-icon::before {
  content: "";
  width: 100%;
  height: 100%;
  border-width: 0.8vmin 0.8vmin 0 0;
  border-style: solid;
  border-color: #fafafa;
  transition: 0.2s ease;
  display: block;
  transform-origin: 100% 0;
}

div.arrow-icon::after {
  content: "";
  float: left;
  position: relative;
  top: -90%;
  width: 100%;
  height: 100%;
  border-width: 0 0.8vmin 0 0;
  border-style: solid;
  border-color: #fafafa;
  transform-origin: 100% 0;
  transition: 0.2s ease;
}

div.arrow-icon:hover::after {
  transform: rotate(45deg);
  border-color: #c868fb;
  height: 100%;
}

div.arrow-icon:hover::before {
  border-color: #c868fb;
  transform: scale(0.8);
}
@media all and (max-width: 380px) {
  #main-container {
    height: 200vw;
  }
  #title {
    width: 95%;
  }
  .pt-8 {
    padding-top: 3rem;
  }
  .text-4xl {
    font-size: 1.5rem;
  }
}
</style>
