<template>
  <div class="font-mono mb-12 w-full">
    <section class="services-container justify-between">
      <div class="block-container flex white-block">
        <!-- Conteneur pour l'image de la flèche supérieure -->
        <div class="flex arrow-container first-arrow">
          <img
            height="170px"
            width="170px"
            src="https://cdn-icons-png.flaticon.com/512/2252/2252531.png"
            alt="Flèche"
          />
        </div>

        <ServiceBlock
          :image="web"
          :title="$t('services.web')"
          :description="
            $t('services.page.web1') +
            $t('services.page.web2') +
            $t('services.page.abbr') +
            $t('services.page.web3') +
            $t('services.page.web4')
          "
        >
        </ServiceBlock>

        <!-- Conteneur pour l'image de la flèche inférieure -->
        <div class="flex arrow-container last-arrow">
          <img
            height="170px"
            width="170px"
            src="https://cdn-icons-png.flaticon.com/512/2252/2252531.png"
            alt="Flèche"
            style=""
          />
        </div>
      </div>
      <div class="card-container justify-between p-8">
        <ServiceCard
          v-show="updateMedia || visibleCardIndex[0] === 0"
          class="fade-up"
          :service-type="11"
          :icon="iconPaintbrush"
          :title="this.$t('services.card.rework')"
          :description="this.$t('services.card.rework-web')"
          :price="1350"
          :features="[
            { text: this.$t('services.card.option.ui'), included: true },
            { text: this.$t('services.card.option.hosting'), included: true },
            { text: this.$t('services.card.option.security'), included: true },
            {
              text: this.$t('services.card.option.responsive'),
              included: true,
            },
            { text: this.$t('services.card.option.rgpd'), included: true },
            { text: 'Maintenance (option)', included: false },
            {
              text: this.$t('services.card.option.seo') + ' (option)',
              included: false,
            },
            {
              text: this.$t('services.card.option.charter') + ' (option)',
              included: false,
            },
          ]"
        />
        <ServiceCard
          v-show="updateMedia || visibleCardIndex[0] === 1"
          class="fade-up"
          :service-type="10"
          :icon="iconConstruction"
          :title="this.$t('services.card.creation')"
          :description="this.$t('services.card.creation-web')"
          :price="1650"
          :features="[
            { text: this.$t('services.card.option.ui'), included: true },
            { text: this.$t('services.card.option.hosting'), included: true },
            { text: this.$t('services.card.option.security'), included: true },
            {
              text: this.$t('services.card.option.responsive'),
              included: true,
            },
            { text: this.$t('services.card.option.rgpd'), included: true },
            {
              text: 'Maintenance ' + this.$t('services.card.option.2h'),
              included: true,
            },
            { text: this.$t('services.card.option.seo'), included: true },
            { text: this.$t('services.card.option.charter'), included: true },
          ]"
        />
        <div class="button-container">
          <button class="carousel-button prev" @click="prevSlide(1)">
            <img class="leftArrow" :src="leftArrow" alt="Précédent" />
          </button>
          <button class="carousel-button next" @click="nextSlide(1)">
            <img class="rightArrow" :src="rightArrow" alt="Suivant" />
          </button>
        </div>
      </div>
    </section>
    <section class="services-container black-services justify-between bg-black">
      <div class="card-container justify-between p-8">
        <ServiceCard
          v-show="updateMedia || visibleCardIndex[1] === 0"
          class="fade-up"
          :service-type="20"
          :icon="iconConstruction"
          :title="this.$t('services.card.creation')"
          :description="this.$t('services.card.creation-mob')"
          :price="2800"
          invertColors="true"
          :features="[
            {
              text: this.$t('services.card.option.data'),
              included: true,
            },
            {
              text: this.$t('services.card.option.adaptability'),
              included: true,
            },
            { text: this.$t('services.card.option.hybrid'), included: true },
            {
              text: this.$t('services.card.option.publication'),
              included: true,
            },
            { text: this.$t('services.card.option.ui'), included: true },
            { text: this.$t('services.card.option.rgpd'), included: true },
            {
              text: 'Maintenance ' + this.$t('services.card.option.2h'),
              included: true,
            },
            { text: this.$t('services.card.option.security'), included: true },
            { text: this.$t('services.card.option.charter'), included: true },
          ]"
        />
        <ServiceCard
          v-show="updateMedia || visibleCardIndex[1] === 1"
          class="fade-up"
          :service-type="21"
          :icon="iconPaintbrush"
          :title="this.$t('services.card.rework')"
          :description="this.$t('services.card.rework-mob')"
          :price="2600"
          invertColors="true"
          :features="[
            {
              text: this.$t('services.card.option.data'),
              included: true,
            },
            {
              text: this.$t('services.card.option.adaptability'),
              included: true,
            },
            { text: this.$t('services.card.option.native'), included: true },
            {
              text: this.$t('services.card.option.publication'),
              included: true,
            },
            { text: this.$t('services.card.option.ui'), included: true },
            { text: this.$t('services.card.option.rgpd'), included: true },
            { text: 'Maintenance (option)', included: false },
            {
              text: this.$t('services.card.option.security') + ' (option)',
              included: false,
            },
            {
              text: this.$t('services.card.option.charter') + ' (option)',
              included: false,
            },
          ]"
        />
        <div class="button-container">
          <button class="carousel-button prev" @click="prevSlide(2)">
            <img class="leftArrow" :src="leftBlackArrow" alt="Précédent" />
          </button>
          <button class="carousel-button next" @click="nextSlide(2)">
            <img class="rightArrow" :src="rightBlackArrow" alt="Suivant" />
          </button>
        </div>
      </div>
      <div class="block-container black-block">
        <!-- Conteneur pour l'image de la flèche supérieure -->
        <div
          class="flex justify-start arrow-container-second first-arrow-second"
        >
          <img height="170px" width="170px" :src="whiteArrow" alt="Flèche" />
        </div>
        <ServiceBlock
          :image="mobile"
          :title="$t('services.mobile')"
          :description="$t('services.page.mobile')"
        >
        </ServiceBlock>
        <!-- Conteneur pour l'image de la flèche inférieure -->
        <div
          class="flex justify-start arrow-container-second last-arrow-second"
        >
          <img height="170px" width="170px" :src="whiteArrow" alt="Flèche" />
        </div>
      </div>
    </section>
    <section class="services-container justify-between">
      <div class="block-container flex white-block">
        <!-- Conteneur pour l'image de la flèche supérieure -->
        <div class="flex arrow-container first-arrow">
          <img
            height="170px"
            width="170px"
            src="https://cdn-icons-png.flaticon.com/512/2252/2252531.png"
            alt="Flèche"
          />
        </div>

        <!-- Développement Software -->
        <ServiceBlock
          :image="software"
          :title="$t('services.software')"
          :description="$t('services.page.software')"
        >
        </ServiceBlock>

        <!-- Conteneur pour l'image de la flèche inférieure -->
        <div class="flex arrow-container last-arrow">
          <img
            height="170px"
            width="170px"
            src="https://cdn-icons-png.flaticon.com/512/2252/2252531.png"
            alt="Flèche"
          />
        </div>
      </div>
      <div class="card-container justify-between p-8">
        <ServiceCard
          v-show="updateMedia || visibleCardIndex[2] === 0"
          class="fade-up"
          :service-type="31"
          :icon="iconPaintbrush"
          :title="this.$t('services.card.rework')"
          :description="this.$t('services.card.rework-sof')"
          :price="2500"
          :features="[
            { text: this.$t('services.card.option.ui'), included: true },
            { text: this.$t('services.card.option.features'), included: true },
            {
              text: this.$t('services.card.option.data'),
              included: true,
            },
            { text: this.$t('services.card.option.update'), included: true },
            { text: this.$t('services.card.option.rgpd'), included: true },
            { text: 'Maintenance (option)', included: false },
            {
              text: this.$t('services.card.option.platforms'),
              included: false,
            },
            {
              text: this.$t('services.card.option.security') + ' (option)',
              included: false,
            },
            {
              text: this.$t('services.card.option.charter') + ' (option)',
              included: false,
            },
          ]"
        />
        <ServiceCard
          v-show="updateMedia || visibleCardIndex[2] === 1"
          class="fade-up"
          :service-type="30"
          :icon="iconConstruction"
          :title="this.$t('services.card.creation')"
          :description="this.$t('services.card.creation-sof')"
          :price="3200"
          :features="[
            { text: this.$t('services.card.option.ui'), included: true },
            { text: this.$t('services.card.option.features'), included: true },
            {
              text: this.$t('services.card.option.data'),
              included: true,
            },
            { text: this.$t('services.card.option.update'), included: true },
            { text: this.$t('services.card.option.rgpd'), included: true },
            {
              text: 'Maintenance ' + this.$t('services.card.option.2h'),
              included: true,
            },
            { text: this.$t('services.card.option.platforms'), included: true },
            { text: this.$t('services.card.option.security'), included: true },
            { text: this.$t('services.card.option.charter'), included: true },
          ]"
        />
        <div class="button-container">
          <button class="carousel-button prev" @click="prevSlide(3)">
            <img class="leftArrow" :src="leftArrow" alt="Précédent" />
          </button>
          <button class="carousel-button next" @click="nextSlide(3)">
            <img class="rightArrow" :src="rightArrow" alt="Suivant" />
          </button>
        </div>
      </div>
    </section>
  </div>
  <section id="options">
    <h2 class="section-title">{{ $t("services.option.+") }}</h2>
    <p class="section-description">{{ $t("services.option.text") }}</p>
    <div class="gradient-bar"></div>
    <div class="options-container">
      <!-- Conteneur pour les labels des icônes -->
      <div class="icon-labels">
        <span>Web</span>
        <span>Mobile</span>
        <span>{{ $t("services.software") }}</span>
      </div>
      <ul>
        <li v-for="(option, index) in computedOptions" :key="option.text">
          <div class="option-item">
            <div class="option-header" @click="toggleDescription(index)">
              <img
                :src="downwardArrow"
                alt="Toggle Description"
                class="toggle-icon"
                :class="{ rotated: expandedIndex === index }"
              />
              <p class="pl-4">{{ option.text }}</p>
            </div>
            <div class="icons">
              <!-- Conteneur pour l'icône Web -->
              <div class="icon-container">
                <img
                  v-if="option.web"
                  class="option-icon"
                  src="https://cdn-icons-png.flaticon.com/512/13903/13903585.png"
                  alt="Site web"
                />
                <img
                  v-else
                  class="option-icon"
                  src="https://cdn-icons-png.flaticon.com/512/7817/7817044.png"
                  alt="Icône indisponible"
                />
              </div>
              <!-- Conteneur pour l'icône Mobile -->
              <div class="icon-container">
                <img
                  v-if="option.mobile"
                  class="option-icon"
                  src="https://cdn-icons-png.flaticon.com/512/13903/13903585.png"
                  alt="Application mobile"
                />
                <img
                  v-else
                  class="option-icon"
                  src="https://cdn-icons-png.flaticon.com/512/7817/7817044.png"
                  alt="Icône indisponible"
                />
              </div>
              <!-- Conteneur pour l'icône Logiciel -->
              <div class="icon-container">
                <img
                  v-if="option.software"
                  class="option-icon"
                  src="https://cdn-icons-png.flaticon.com/512/13903/13903585.png"
                  alt="Logiciel"
                />
                <img
                  v-else
                  class="option-icon"
                  src="https://cdn-icons-png.flaticon.com/512/7817/7817044.png"
                  alt="Icône indisponible"
                />
              </div>
            </div>
          </div>
          <div v-if="expandedIndex === index" class="option-description">
            <p>{{ option.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import ServiceBlock from "./ServiceBlock.vue";
import ServiceCard from "./ServiceCard.vue";

import web from "@/assets/single-images/isometricLaptop.webp";
import mobile from "@/assets/single-images/isometricPhone.webp";
import software from "@/assets/single-images/logiciel.webp";
import iconPaintbrush from "@/assets/icon/maintenance.png";
import iconConstruction from "@/assets/icon/travaux-routiers.png";
import whiteArrow from "@/assets/icon/flèches-blanches.png";
import rightArrow from "@/assets/icon/flèche-droite.png";
import leftArrow from "@/assets/icon/flèche-gauche.png";
import rightBlackArrow from "@/assets/icon/flèche-droite-noire.png";
import leftBlackArrow from "@/assets/icon/flèche-gauche-noire.png";
import downwardArrow from "@/assets/icon/flèche-dépliante.png";

export default {
  name: "WebMobileDevelopment",
  components: {
    ServiceBlock,
    ServiceCard,
  },
  data() {
    return {
      iconPaintbrush,
      iconConstruction,
      web,
      mobile,
      software,
      whiteArrow,
      rightArrow,
      leftArrow,
      rightBlackArrow,
      leftBlackArrow,
      downwardArrow,
      visibleCardIndex: [0, 0, 0],
      windowWidth: window.innerWidth,
      expandedIndex: null,
    };
  },
  computed: {
    computedOptions() {
      return [
        {
          text: this.$t("services.option.greenIt.text"),
          description: this.$t("services.option.greenIt.description"),
          web: true,
          mobile: true,
          software: true,
        },
        {
          text: this.$t("services.option.bdd.text"),
          description: this.$t("services.option.bdd.description"),

          web: true,
          mobile: true,
          software: true,
        },
        {
          text: this.$t("services.option.darkmode.text"),
          description: this.$t("services.option.darkmode.description"),
          web: true,
          mobile: true,
          software: true,
        },
        {
          text: this.$t("services.option.languages.text"),
          description: this.$t("services.option.languages.description"),
          web: true,
          mobile: true,
          software: true,
        },
        {
          text: this.$t("services.option.maj.text"),
          description: this.$t("services.option.maj.description"),
          web: true,
          mobile: true,
          software: true,
        },
        {
          text: this.$t("services.option.doc.text"),
          description: this.$t("services.option.doc.description"),
          web: true,
          mobile: true,
          software: true,
        },
        {
          text: this.$t("services.option.logo.text"),
          description: this.$t("services.option.logo.description"),
          web: true,
          mobile: true,
          software: true,
        },
        {
          text: this.$t("services.option.com.text"),
          description: this.$t("services.option.com.description"),
          web: true,
          mobile: true,
          software: true,
        },
        {
          text: this.$t("services.option.analysis.text"),
          description: this.$t("services.option.analysis.description"),
          web: true,
          mobile: true,
          software: false,
        },
        {
          text: this.$t("services.option.conso.text"),
          description: this.$t("services.option.conso.description"),
          web: false,
          mobile: true,
          software: false,
        },
        {
          text: this.$t("services.option.notif.text"),
          description: this.$t("services.option.notif.description"),
          web: false,
          mobile: true,
          software: false,
        },
      ];
    },
    updateMedia() {
      return this.windowWidth > 1130;
    },
  },
  methods: {
    nextSlide(value) {
      this.visibleCardIndex[value - 1] =
        (this.visibleCardIndex[value - 1] + 1) % 2;
    },
    prevSlide(value) {
      this.visibleCardIndex[value - 1] =
        (this.visibleCardIndex[value - 1] - 1 + 2) % 2;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;

      // Forcer l'évaluation de `updateMedia`
      this.$nextTick(() => {
        if (this.windowWidth > 1130) {
          document.querySelectorAll(".fade-up").forEach((card) => {
            card.classList.add("visible");
          });
        }
      });
    },
    observeCards() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              observer.unobserve(entry.target);
            }
          });
        },
        {
          threshold: 0.2,
        }
      );

      document.querySelectorAll(".fade-up").forEach((card) => {
        observer.observe(card);
      });
    },
    toggleDescription(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.observeCards();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.services-container {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.card-container {
  margin-block: auto;
  display: flex;
  width: 50%;
  position: relative; /* Ajouté pour positionner les boutons relativement au conteneur */
}
.fade-up {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-up.visible {
  opacity: 1;
  transform: translateY(0);
}

.block-container {
  width: 45%;
  flex-direction: column;
}

.white-block {
  margin-left: 2%;
}

.black-block {
  margin-right: 2%;
}

.arrow-container,
.arrow-container-third {
  justify-content: end;
}

.first-arrow-second img {
  transform: rotate(180deg) rotateX(180deg);
}

.last-arrow-second img {
  transform: rotate(180deg);
}

.last-arrow img {
  transform: rotateX(180deg);
}

/* Centrage vertical des boutons */
.button-container {
  display: none;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px; /* Add some padding to align the buttons properly */
  width: 488px;
  height: 70px;
}

.leftArrow,
.rightArrow {
  height: 100%;
}

button {
  border-radius: 50%; /* Make sure buttons are circular */
  z-index: 2; /* Ensure buttons are above the background */
}
#options {
  width: 750px;
}

.section-title {
  font-family: "Poppins", sans-serif;
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.section-description {
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 25px;
}

.gradient-bar {
  height: 4px;
  width: 100%;
  background: linear-gradient(135deg, #2799ec, #f65aff);
}

.options-container {
  padding: 20px 0;
}

.icon-labels {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.icon-labels span {
  padding-inline: 3px;
  width: 64px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.option-header {
  display: flex;
  align-items: center;
}

.toggle-icon {
  height: 35px;
  transition: transform 0.5s ease;
}
.rotated {
  transform: rotate(180deg);
}

.option-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
}

.option-description {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin-block: 10px;
}

.icons {
  display: flex;
  align-items: center;
}

.icon-container {
  width: 50px; /* Largeur fixe pour chaque conteneur d'icône */
  text-align: center;
  margin-left: 15px;
}

.option-icon {
  width: 45px;
  height: 45px;
}

@media all and (max-width: 1775px) {
  .offset {
    width: 80%;
  }
  .services-container {
    display: block;
    height: auto;
  }

  .black-services {
    display: flex;
    flex-direction: column-reverse;
  }

  .card-container {
    width: 1170px;
    margin: auto;
  }

  .block-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  .white-block,
  .black-block {
    margin-inline: 0;
  }

  .services-container {
    padding-top: 70px;
  }

  .arrow-container,
  .arrow-container-second {
    align-items: end;
  }

  .first-arrow img {
    transform: rotate(95deg) rotateX(180deg);
  }

  .last-arrow img {
    transform: rotate(85deg);
  }

  .first-arrow-second img {
    transform: rotate(95deg) rotateX(180deg);
  }

  .last-arrow-second img {
    transform: rotate(85deg);
  }
}

@media all and (max-width: 1400px) {
  .card-container {
    width: 80%;
  }
}

@media all and (max-width: 1130px) {
  .offset {
    width: 100%;
  }

  .button-container {
    display: flex !important;
  }
  button {
    display: block !important;
  }

  .card-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* .card-container > *:not(:first-child) {
    display: none;
  } */

  .arrow-container img,
  .arrow-container-second img {
    position: absolute;
  }

  .first-arrow img,
  .first-arrow-second img {
    left: 30px;
    transform: rotate(95deg) rotateX(180deg) translateX(235px);
  }

  .last-arrow img,
  .last-arrow-second img {
    right: 30px;
    transform: rotate(85deg) translateX(235px);
  }

  .carousel-button {
    width: 70px;
    height: 100%;
  }
}

@media all and (max-width: 830px) {
  #options {
    width: 90%;
  }
}

@media all and (max-width: 680px) {
  .arrow-container img,
  .arrow-container-second img {
    height: 120px;
    width: 120px;
  }

  .first-arrow img,
  .first-arrow-second img {
    transform: rotate(95deg) rotateX(180deg) translateX(140px);
  }

  .last-arrow img,
  .last-arrow-second img {
    transform: rotate(85deg) translateX(140px);
  }

  .button-container {
    top: 60%;
  }
}

@media all and (max-width: 490px) {
  .button-container {
    width: 100%;
    padding: 0;
  }
  .leftArrow,
  .rightArrow {
    transform: scale(0.8);
  }
  .option-item {
    font-size: 1.1rem;
  }
  .option-item p {
    width: 31%;
  }

  .icon-labels span {
    width: 49px;
    font-size: 0.8rem;
  }

  .icon-container {
    width: 40px;
    margin-left: 10px;
  }
  .option-icon {
    width: 35px;
    height: 35px;
  }
  .toggle-icon {
    height: 30px;
  }
}

@media all and (max-width: 440px) {
  .button-container {
    width: 70%;
    top: 95%;
  }
}

@media all and (max-width: 385px) {
  .option-item {
    font-size: 0.9rem;
  }
  .icon-container {
    width: 35px;
    margin-left: 0;
  }
  .icon-labels span {
    width: 38px;
    font-size: 0.6rem;
  }
  .option-icon {
    width: 25px;
    height: 25px;
  }
  .toggle-icon {
    height: 25px;
  }
}
@media all and (max-width: 325px) {
  .option-item {
    font-size: 0.7rem;
  }
  .option-icon {
    width: 20px;
    height: 20px;
  }
  .icon-container {
    width: 25px;
  }
  .toggle-icon {
    height: 20px;
  }
}
</style>
