import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFound from "../views/NotFound.vue";
import KortleLogo from "@/assets/logo/kortle-nobg.png";
import ServiceView from "../views/ServiceView.vue";
import ContactPage from "@/views/ContactPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      title: "Kortle | Accueil",
      property: "og:title",
      content: "Kortle | Intégrateur et fournisseur de solutions informatiques",
      icon: KortleLogo,
    },
  },
  {
    path: "/services",
    name: "Services",
    component: ServiceView,
    meta: {
      title: "Kortle | Services",
    },
  },
  {
    path: "/contact",
    name: "ContactPage",
    component: ContactPage,
    meta: {
      title: "Kortle | Contact",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 - Page non trouvée",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Kortle";
  next();
});

export default router;
