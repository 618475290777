<template>
  <div class="services">
    <web-mobile-development></web-mobile-development>
  </div>
</template>

<script>
import WebMobileDevelopment from "@/components/Services/WebMobileDev.vue";

export default {
  name: "ServicesView",
  components: {
    WebMobileDevelopment,
  },
};
</script>

<style scoped>
.services {
  padding-top: 150px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
</style>
