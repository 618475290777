<template>
  <div class="offset">
    <h2 class="font-bold text-center text-black uppercase">
      {{ title }}
    </h2>
    <div
      class="service-block flex flex-col md:flex-row md:items-center my-auto w-full"
      :class="{ 'fade-down': isVisible }"
      ref="serviceBlock"
    >
      <div id="highlight" class="h-full flex justify-center items-center">
        <img
          :alt="title"
          :src="image"
          class="h-auto max-h-full w-full object-contain"
        />
      </div>
      <div class="text-content flex-1 py-4 h-full">
        <p class="text-white text-justify my-8">
          {{ description }}
        </p>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceBlock",
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    this.observeVisibility();
  },
  methods: {
    observeVisibility() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.isVisible = true;
              observer.unobserve(entry.target);
            }
          });
        },
        {
          threshold: 0.25,
        }
      );

      observer.observe(this.$refs.serviceBlock);
    },
  },
};
</script>
<style scoped>
img {
  animation: float 3s ease-in-out infinite;
  max-height: 100%;
  object-fit: contain;
}

h2 {
  margin-block: 1rem;
  font-size: 45px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 700;
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
}
p {
  font-size: 18px;
}

.service-block {
  border-radius: 20px;
  margin-bottom: auto;
  overflow: hidden;
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  transform: translateY(-50px);
}
.offset {
  position: relative;
  top: -30px;
}
.text-content {
  padding-right: 2rem;
}

#highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

@media all and (max-width: 1775px) and (min-width: 1410px) {
  h2 {
    font-size: 35px;
  }
  p {
    font-size: 23px;
  }
}
@media all and (max-width: 1410px) and (min-width: 1190px) {
  h2 {
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 21px;
  }
}
@media all and (max-width: 1130px) {
  .service-block {
    flex-direction: column;
  }
  .text-content {
    padding-inline: 1rem;
  }
}
@media all and (max-width: 1130px) {
  .service-block {
    width: 90%;
    margin-inline: auto;
  }
  #highlight {
    width: 100%;
  }
  #highlight img {
    height: 320px;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.fade-down {
  opacity: 1;
  transform: translateY(0);
}
</style>
