<template>
  <nav class="w-full mx-auto relative">
    <div id="line-top" class="bg-white h-4 absolute z-1"></div>
    <div id="line-bottom" class="bg-white h-4 absolute z-1"></div>
    <h2 class="font-bold text-white text-5xl pt-12 text-center">
      {{ $t("services.title") }}
    </h2>
    <div class="pt-8 circle-container flex flex-wrap mx-auto justify-between">
      <div class="service-item relative z-10 mb-8">
        <div class="text-container mx-auto text-center">
          <p class="text-white text-3xl title-animate">
            {{ $t("services.web") }}
          </p>
        </div>
        <router-link :to="{ path: '/services' }">
          <div
            class="mx-auto circle border-4 border-white rounded-full flex items-center justify-center w-full h-full"
          >
            <img :src="isometricLaptop" alt="" class="float-img" />
          </div>
        </router-link>
      </div>
      <div class="service-item relative z-10 mb-8">
        <div class="text-container mx-auto text-center">
          <p class="text-white text-3xl title-animate">
            {{ $t("services.mobile") }}
          </p>
        </div>
        <router-link :to="{ path: '/services' }">
          <div
            class="mx-auto circle border-4 border-white rounded-full flex items-center justify-center w-full h-full"
          >
            <img :src="isometricPhone" alt="" class="float-img" />
          </div>
        </router-link>
      </div>
      <div class="service-item relative z-10 mb-8">
        <div class="text-container mx-auto text-center">
          <p class="text-white text-3xl title-animate">
            {{ $t("services.software") }}
          </p>
        </div>
        <router-link :to="{ path: '/services' }">
          <div
            class="mx-auto circle border-4 border-white rounded-full flex items-center justify-center w-full h-full"
          >
            <img :src="software" alt="" class="float-img w-10/12" />
          </div>
        </router-link>
      </div>
      <div class="service-item relative z-10 mb-8">
        <div class="text-container mx-auto text-center">
          <p class="text-white text-3xl title-animate">Reporting</p>
        </div>
        <router-link :to="{ path: '/services' }">
          <div
            class="mx-auto circle border-4 border-white rounded-full flex items-center justify-center w-full h-full"
          >
            <img :src="webAnalytics" alt="" class="float-img" />
          </div>
        </router-link>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <button @click="goToServicesPage" class="btn-see-more">
        {{ $t("services.plus") }}
      </button>
    </div>
  </nav>
</template>

<script>
import isometricLaptop from "@/assets/single-images/isometricLaptop.webp";
import isometricPhone from "@/assets/single-images/isometricPhone.webp";
import webAnalytics from "@/assets/single-images/webAnalytics.webp";
import software from "@/assets/single-images/logiciel.webp";

export default {
  name: "NosServices",
  data() {
    return {
      isometricLaptop,
      isometricPhone,
      webAnalytics,
      software,
    };
  },
  mounted() {
    this.startAnimations();
  },
  methods: {
    startAnimations() {
      const images = document.querySelectorAll(".float-img");
      let index = 0;

      const animateNextImage = () => {
        images.forEach((img) => img.classList.remove("animate"));
        images[index].classList.add("animate");

        setTimeout(() => {
          index = (index + 1) % images.length;
          animateNextImage();
        }, 2000);
      };

      animateNextImage();
    },
    goToServicesPage() {
      this.$router.push("/services");
    },
  },
};
</script>

<style scoped>
a {
  height: 100%;
}

nav {
  background: linear-gradient(
    90deg,
    rgba(14, 6, 29, 1) 0%,
    rgba(30, 9, 52, 1) 35%,
    rgba(56, 21, 75, 1) 70%,
    rgba(68, 27, 64, 1) 100%
  );
  padding: 2rem 0;
  position: relative;
}

.circle-container {
  max-width: 90%;
}

.text-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5rem; /* hauteur minimale pour uniformiser la hauteur des conteneurs */
}

#line-top,
#line-bottom {
  width: 100%;
  height: 5px;
}

#line-top {
  top: 56%;
}

#line-bottom {
  display: none;
  transform: rotate(90deg);
  top: 65%;
}

.circle {
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
}

p,
h2 {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

img {
  transition: transform 1s;
}
img:hover {
  cursor: pointer;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate {
  animation: float 2s ease-in-out infinite;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title-animate {
  opacity: 0;
  animation: fadeInUp 1s ease-out forwards;
}

.btn-see-more {
  display: inline-block;
  padding: 0.5rem 2rem;
  margin-top: 2rem;
  background-color: #f65aff;
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.btn-see-more:hover {
  background-color: #2799ec;
  transform: translateY(-5px);
}

.service-item {
  flex: 1 1 22%;
  max-width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media all and (max-width: 1140px) {
  #line-top {
    top: 57%;
  }
  .text-3xl {
    font-size: 2.5vw;
  }
}
@media all and (max-width: 910px) {
  #line-top {
    top: 58%;
  }
}

@media (max-width: 768px) {
  p {
    background-color: #2799ec;
    padding: 10px;
  }
  .service-item {
    flex: 1 1 100%;
    max-width: 100%;
    text-align: center;
  }

  .circle {
    height: 50vw;
    width: 50vw;
  }

  .text-container p {
    font-size: 1.5rem;
  }

  .btn-see-more {
    font-size: 1rem;
  }

  .mb-8 {
    margin-bottom: 1rem;
  }
  #line-bottom {
    display: block;
  }
  #line-top {
    transform: rotate(90deg);
    top: 40%;
  }
}
</style>
