<template>
  <div class="not-found">
    <div class="error-container">
      <img :src="image404" alt="Page non trouvée" class="error-image" />
      <h1>404</h1>
      <p>Page non trouvée</p>
      <router-link to="/" class="home-link">Retour à l'accueil</router-link>
    </div>
  </div>
</template>

<script>
import image404 from "@/assets/single-images/404.png";
export default {
  name: "NotFound",
  data() {
    return {
      image404,
    };
  },
};
</script>

<style scoped>
.not-found {
  position: relative;
  padding-top: 170px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f4f4f4;
  color: #333;
}

.error-container {
  text-align: center;
  background: #fff;
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 80%;
}

.error-image {
  height: 400px;
  margin-bottom: 1rem;
}

h1 {
  font-size: 6rem;
  color: #ff4d4d;
  margin: 0;
}

p {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.home-link {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  border: 2px solid #007bff;
  border-radius: 4px;
  transition: background 0.3s, color 0.3s;
}

.home-link:hover {
  background: #007bff;
  color: #fff;
}
</style>
