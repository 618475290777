<template>
  <div id="container" class="w-full">
    <div id="sub-container" class="w-full h-full flex items-center">
      <div
        class="h-full w-1/3 third-image"
        :style="{ backgroundImage: `url(${bgInformaticien})` }"
      ></div>
      <div
        class="mid-element h-full mx-auto w-1/3 items-center flex"
        style="background-image: linear-gradient(135deg, #2799ec, #f65aff)"
        @click="this.$router.push({ name: 'ContactPage' })"
      >
        <div class="mx-auto mask-container">
          <span class="mask-text">{{ $t("menus.2") }}</span>
          <div class="mask-overlay"></div>
        </div>
      </div>
      <div
        class="h-full w-1/3 third-image"
        :style="{ backgroundImage: `url(${bgDesigner})` }"
      ></div>
    </div>
  </div>
</template>

<script>
import bgInformaticien from "@/assets/homemade images/service-informaticien.webp";
import bgDesigner from "@/assets/homemade images/service-design.webp";

export default {
  name: "NosServices",
  data() {
    return {
      bgInformaticien,
      bgDesigner,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400");

#container {
  height: 30rem;
}

#sub-container {
  background-size: cover;
  background-position: center;
  backdrop-filter: brightness(0.5);
}

.third-image {
  background-size: cover;
  background-position: center;
}

.mid-element {
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.mask-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mask-text {
  position: absolute;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 2rem;
  z-index: 2;
}

.mask-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  -webkit-mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/nature-sprite.png");
  mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/nature-sprite.png");
  -webkit-mask-size: 2300% 100%;
  mask-size: 2300% 100%;
  animation: mask-animation 0.7s steps(22) forwards;
  z-index: 1;
}

.mid-element:hover .mask-overlay {
  animation: mask-animation-reverse 0.7s steps(22) forwards;
}

@keyframes mask-animation {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes mask-animation-reverse {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@media all and (max-width: 1100px) {
  #container {
    height: 100rem;
  }
  #sub-container {
    flex-direction: column;
  }
  #sub-container div {
    width: 100%;
  }
  .third-image {
    height: 100vw;
  }
  .mid-element {
    height: 200px;
  }
}

@media all and (max-width: 700px) {
  #container {
    height: 100%;
  }
}
</style>
