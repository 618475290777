<template>
  <div class="pt-6">
    <p ref="priceDisplay" class="priceContainer pb-6 px-4 flex justify-center items-center">
      {{ $t("services.modal.estimatedPrice") }}
      <span class="price">&nbsp;{{ animatedPrice + " €" }}</span>
    </p>

    <!-- Récapitulatif des options sélectionnées -->
    <div class="options-summary mt-4">
      <h4 class="font-bold text-xl mb-3">{{ $t("services.modal.summary") }}</h4>
      <ul class="list-disc ml-6">
        <li v-if="service === 10 || service === 11">{{ $t("services.modal.options.siteTypeLabel") }} : <span>{{ $t(`services.modal.options.siteTypes.${selectedSiteType}`) }}</span></li>
        <li v-if="service === 10 || service === 11">{{ $t("services.modal.options.pageCount") }} : <span>{{ $t(`services.modal.options.pageOrViewCount.${selectedPageOrViewCount}`) }}</span></li>
        <li v-if="service === 20 || service === 21">{{ $t("services.modal.options.viewCount") }} : <span>{{ $t(`services.modal.options.pageOrViewCount.${selectedPageOrViewCount}`) }}</span></li>
        <li>{{ $t("services.modal.options.delayLabel") }} : <span>{{ $t(`services.modal.options.delayOptions.${selectedDelay}`) }}</span></li>
        <li>{{ $t("services.modal.options.designLabel") }} : <span>{{ $t(`services.modal.options.designOptions.${selectedDesign}`) }}</span></li>
        <li>{{ $t("services.modal.options.translationCheckbox") }} : <span>{{ isTranslationNeeded ? $t("yes") : $t("no") }}</span></li>
        <li>{{ $t("services.modal.options.darkModeCheckbox") }} : <span>{{ isDarkModeEnabled ? $t("yes") : $t("no") }}</span></li>
      </ul>
    </div>

    <div class="mt-4 text-white font-bold">
      <button
          class="bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded shadow-md w-full"
          @click="saveEstimation"
      >
        {{ $t("services.modal.save") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EstimatedPrice",
  props: {
    estimatedPrice: {
      type: Number,
      required: true,
    },
    service: {
      type: Number,
      required: true,
    },
    selectedSiteType: {
      type: String,
      required: true,
    },
    selectedPageOrViewCount: {
      type: String,
      required: true,
    },
    selectedDelay: {
      type: String,
      required: true,
    },
    selectedDesign: {
      type: String,
      required: true,
    },
    isTranslationNeeded: {
      type: Boolean,
      required: true,
    },
    isDarkModeEnabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      animatedPrice: 0, // prix animé pour l'affichage
      hasAnimatedPrice: false, // pour s'assurer que l'animation ne se lance qu'une fois
      duration: 1500, // durée de l'animation
      startPriceAnimation: null, // point de départ pour l'animation
    };
  },
  methods: {
    animatePrice() {
      const animateNumbers = (timestamp) => {
        if (!this.startPriceAnimation) this.startPriceAnimation = timestamp;
        const progress = timestamp - this.startPriceAnimation;

        // Calculer la valeur animée du prix
        this.animatedPrice = Math.min(
            Math.floor((progress / this.duration) * this.estimatedPrice),
            this.estimatedPrice
        );

        if (progress < this.duration) {
          requestAnimationFrame(animateNumbers);
        } else {
          this.animatedPrice = this.estimatedPrice; // Assurez-vous que l'animation se termine avec le prix final
        }
      };
      requestAnimationFrame(animateNumbers);
    },

    checkPriceVisibility(entries) {
      const [entry] = entries;
      if (entry.isIntersecting && !this.hasAnimatedPrice) {
        this.animatePrice();
        this.hasAnimatedPrice = true; // Éviter de relancer l'animation si déjà effectuée
      }
    },
    saveEstimation() {
      this.$emit("save");
    },
  },
  mounted() {
    // Créer un observateur pour déclencher l'animation du prix
    const priceObserver = new IntersectionObserver(this.checkPriceVisibility, {
      threshold: 0.1, // Déclenche l'animation lorsque 10% du composant est visible
    });

    priceObserver.observe(this.$refs.priceDisplay); // Observer l'élément de prix
  },
};
</script>

<style scoped>
.priceContainer {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

span {
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: bold;
}

.price {
  display: block;
  transition: color 0.3s ease;
}

.price:hover {
  color: #f65aff;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media all and (max-width: 800px) {
  li {
    display: block;
  }
}

@media all and (max-width: 500px) {
  .price,
  .priceContainer {
    font-size: 23px;
  }

  .priceContainer {
    justify-content: center;
    text-align: center;
  }
}

@media all and (max-width: 380px) {
  .price,
  .priceContainer {
    font-size: large;
  }
}
</style>
