<template>
  <div class="w-full contact-page">
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/Contact/ContactForm.vue";

export default {
  name: "ContactPage",
  components: { ContactForm },
};
</script>

<style scoped>
.contact-page {
  padding-top: 165px;
  padding-bottom: 25px;
  background: linear-gradient(90deg, #0e061d, #1e0934 35%, #38154b 70%, #441b40);
}
</style>
