<template>
  <header :class="headerClass">
    <NavBar></NavBar>
  </header>
  <main>
    <router-view></router-view>
  </main>
  <FooterComponent></FooterComponent>
</template>

<script>
import NavBar from "@/components/NavBarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterComponent,
  },
  computed: {
    headerClass() {
      return this.$route.path === "/services" ||
        this.$route.matched.some((record) => record.path === "/:pathMatch(.*)*")
        ? "header-colored"
        : "";
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-y: hidden;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.header-colored {
  background-color: #4c1db3;
}
</style>
