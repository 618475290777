<template>
  <div ref="statsCounter" class="stats-counter">
    <div class="stat-item">
      <span class="stat-number">{{ formattedYears }}</span>
      <p class="stat-label">{{ $t("stats.years") }}</p>
    </div>
    <div class="stat-item">
      <span class="stat-number">{{ formattedClients }}</span>
      <p class="stat-label">{{ $t("stats.clients") }}</p>
    </div>
    <div class="stat-item">
      <span class="stat-number">{{ formattedProjects }}</span>
      <p class="stat-label">{{ $t("stats.projects") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsCounter",
  data() {
    return {
      projects: 3,
      clients: 3,
      revenue: 5,
      duration: 2000,
      startTime: null,
      formattedProjects: 0,
      formattedClients: 0,
      formattedYears: 0,
      hasAnimated: false, // Pour s'assurer que l'animation ne se lance qu'une fois
    };
  },
  methods: {
    animate() {
      const animateNumbers = (timestamp) => {
        if (!this.startTime) this.startTime = timestamp;
        const progress = timestamp - this.startTime;

        this.formattedProjects = Math.min(
          Math.floor((progress / this.duration) * this.projects),
          this.projects
        );
        this.formattedClients = Math.min(
          Math.floor((progress / this.duration) * this.clients),
          this.clients
        );
        this.formattedYears = Math.min(
          Math.floor((progress / this.duration) * this.revenue),
          this.revenue
        );

        if (progress < this.duration) {
          requestAnimationFrame(animateNumbers);
        } else {
          this.formattedProjects = this.projects;
          this.formattedClients = this.clients;
          this.formattedYears = this.revenue;
        }
      };

      requestAnimationFrame(animateNumbers);
    },
    checkVisibility(entries) {
      const [entry] = entries;
      if (entry.isIntersecting && !this.hasAnimated) {
        this.animate();
        this.hasAnimated = true; // Éviter de relancer l'animation si déjà effectuée
      }
    },
  },
  mounted() {
    const observer = new IntersectionObserver(this.checkVisibility, {
      threshold: 0.1, // Déclenche l'animation lorsque 10% du composant est visible
    });

    observer.observe(this.$refs.statsCounter);
  },
};
</script>

<style scoped>
.stats-counter {
  display: flex;
  justify-content: space-around;
  padding: 3rem 1rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat-item {
  flex: 1;
  margin: 0 1rem;
}

.stat-number {
  display: block;
  font-size: 3rem;
  font-weight: bold;
  color: #2799ec;
  transition: color 0.3s ease;
}

.stat-label {
  font-size: 1.2rem;
  color: #333;
  margin-top: 0.5rem;
  font-weight: 600;
}

.stats-counter:hover .stat-number {
  color: #f65aff; /* Couleur au survol */
}

@media all and (max-width: 768px) {
  .stats-counter {
    flex-direction: column;
    align-items: center;
  }
  .stat-item {
    margin-bottom: 1rem;
  }
}
</style>
