<template>
  <div class="contact-container">
    <h1 class="text-3xl font-extrabold mb-6 text-gray-500">{{ $t('contact.title')}}</h1>
    <form @submit.prevent="handleSubmit" class="space-y-6">
      <div class="flex flex-col">
        <label for="name" class="text-lg font-semibold text-gray-500 mb-1"
          >{{ $t('contact.name')}}</label
        >
        <input
          type="text"
          id="name"
          name="name"
          v-model="form.name"
          required
          class="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div class="flex flex-col">
        <label for="email" class="text-lg font-semibold text-gray-500 mb-1"
          >Email</label
        >
        <input
          type="email"
          id="email"
          name="email"
          v-model="form.email"
          required
          class="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div class="flex flex-col">
        <label for="message" class="text-lg font-semibold text-gray-500 mb-1"
          >Message</label
        >
        <textarea
          id="message"
          name="message"
          v-model="form.message"
          required
          rows="5"
          class="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        ></textarea>
      </div>
      <button
        type="submit"
        :class="[
          'bg-blue-600 font-bold text-white px-6 py-3 rounded-lg shadow-md transform transition-transform duration-300 ease-in-out',
          { 'opacity-50 cursor-not-allowed': isSubmitting, 'hover:scale-105 hover:bg-blue-700': !isSubmitting }
        ]"
        :disabled="isSubmitting"
      >
        {{ $t('contact.send') }}
      </button>
      <p
        v-if="submissionStatus"
        class="mt-4 text-lg font-medium"
        :class="{ 'text-green-600': success, 'text-red-600': !success }"
      >
        {{ submissionStatus }}
      </p>
    </form>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
      submissionStatus: "",
      success: false,
      isSubmitting: false,  // Variable pour contrôler l'état du bouton
    };
  },
  methods: {
    handleSubmit() {
      this.isSubmitting = true;  // Désactiver le bouton au début du traitement
      emailjs
        .send(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
          this.form,
          process.env.VUE_APP_EMAILJS_PUBLIC_KEY
        )
        .then(() => {
          this.submissionStatus = this.$t('contact.success');
          this.success = true;
          this.form.name = "";
          this.form.email = "";
          this.form.message = "";
        })
        .catch(() => {
          this.submissionStatus = this.$t('contact.error');
          this.success = false;
        })
        .finally(() => {
          this.isSubmitting = false;  // Réactiver le bouton après traitement
        });
    },
  },
};
</script>

<style scoped>
.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
}
</style>
