<template>
  <transition name="slide-fade">
    <div v-if="visible" class="snackbar">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "SnackBar",
  props: {
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 3000,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    showSnackbar() {
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, this.duration);
    },
  },
  mounted() {
    this.showSnackbar();
  },
};
</script>

<style scoped>

.snackbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 16px 36px 16px 36px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  z-index: 1000;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

@media all and (max-width: 800px) {
  .snackbar {
    padding: 12px 12px 12px 12px;
  }
}

@media all and (max-width: 500px) {
  .snackbar {
    width: 70%;
    padding: 12px 12px 12px 12px;
  }
}
</style>
