<template>
  <div
    id="card-container"
    class="mx-auto z-50 absolute left-0 right-0"
    ref="dimension"
    @click="redirectToUrl"
    :style="{ cursor: isCardCentered ? 'pointer' : 'auto' }"
  >
    <div
      class="slide mx-auto absolute"
      :style="{ backgroundImage: `url(${imageUrl})` }"
      style="box-shadow: 0px 0px 20px 5px #000000"
      ref="container"
    ></div>
    <div class="text-content mx-auto absolute" ref="textContent">
      <div
        class="bg-white rounded-xl shadow-2xl"
        :style="{
          transitionDuration: '0.75s',
          transitionDelay: isCardCentered ? '0.75s' : '0s',
          transitionProperty: 'transform  ease, backdrop-filter ease',
          transform: isCardCentered ? 'translateY(0)' : 'translateY(180%)',
        }"
      >
        <p
          class="title mx-auto w-48 py-2 font-bold text-3xl"
          :style="{
            transitionDuration: '0.75s',
            transitionDelay: isCardCentered ? '0.75s' : '0s',
            transitionProperty: 'transform  ease, backdrop-filter ease',
            transform: isCardCentered ? 'translateY(0)' : 'translateY(180%)',
          }"
        >
          {{ title }}
        </p>
      </div>
      <p
        class="description mx-auto w-40 py-2 font-semibold"
        :style="{
          transitionDuration: '0.75s',
          transitionDelay: isCardCentered ? '0.75s' : '0s',
          transitionProperty: 'transform  ease',
          transform: isCardCentered ? 'translateY(0)' : 'translateY(180%)',
        }"
      >
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardComponent",
  props: {
    title: String,
    description: String,
    imageUrl: String,
    isCardCentered: Boolean,
    redirectTo: String,
  },

  mounted() {
    this.container = this.$refs.container;
    this.dimension = this.$refs.dimension;
    this.textContent = this.$refs.textContent;

    this.dimension.addEventListener("mousemove", this.handleMouseMove);
    this.dimension.addEventListener("mouseleave", this.handleMouseLeave);
  },
  beforeUnmount() {
    if (this.dimension) {
      this.dimension.removeEventListener("mousemove", this.handleMouseMove);
      this.dimension.removeEventListener("mouseleave", this.handleMouseLeave);
    }
  },
  methods: {
    handleMouseMove(e) {
      if (this.isCardCentered === true) {
        if (this.$refs.textContent) {
          this.$refs.container.style.transition = "0s";
          this.$refs.textContent.style.transition = "0s";
          const { offsetWidth: width, offsetHeight: height } = this.container;
          const { offsetX: x, offsetY: y } = e;
          const move = 30;
          const xMove = (x / width - 0.5) * move;
          const yMove = (y / height - 0.5) * move;

          this.$refs.container.style.transform = `perspective(1000px) rotateX(${-yMove}deg) rotateY(${xMove}deg)`;
          this.$refs.textContent.style.transform = `rotateX(${-yMove}deg) rotateY(${xMove}deg)`;
          this.$refs.container.style.backgroundPosition = `${50 + xMove}% ${
            50 + yMove
          }%`;
        }
      }
    },
    handleMouseLeave() {
      if (this.$refs.textContent) {
        this.resetTransform();
      }
    },
    resetTransform() {
      if (this.$refs.container && this.$refs.textContent) {
        this.$refs.container.style.transition =
          "transform 1s ease, background-position 1s ease";
        this.$refs.textContent.style.transition = "transform 1s ease";
        this.$refs.container.style.transform =
          "perspective(1000px) rotateX(0deg) rotateY(0deg)";
        this.$refs.textContent.style.transform =
          "translateZ(0) rotateX(0deg) rotateY(0deg)";
        this.$refs.container.style.backgroundPosition = "50% 50%";
      }
    },
    redirectToUrl() {
      if (this.redirectTo && this.isCardCentered) {
        window.open(this.redirectTo, "_blank");
      }
    },
  },
};
</script>

<style scoped>
#card-container {
  height: 500px;
  width: 320px;
}
.slide {
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
}

.text-content {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  color: white;
  top: 70%;
  right: 55%;
}

.title,
.description {
  position: relative;
  font-family: "Archivo", sans-serif;
}

.title {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
  -webkit-background-clip: text;
  background-clip: text;
}

@media all and (max-width: 480px) {
  .text-content {
    top: 75%;
    right: 21%;
  }
}
@media all and (max-width: 380px) {
  #card-container {
    height: 145vw;
    width: 83vw;
  }
}
@media all and (max-width: 380px) {
  .text-content {
    width: 50vw;
  }
  .title {
    width: 100%;
    font-size: 8vw;
  }
  .description {
    width: 100%;
    font-size: 4vw;
  }
}
@media all and (max-width: 320px) {
  .title {
    font-size: 7vw;
  }
}
</style>
