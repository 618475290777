<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="footer-col">
          <h4>{{ $t("footer.team") }}</h4>
          <ul>
            <li>
              <a href="https://comebonal.com" target="_blank">Côme Bonal</a>
            </li>
            <li>
              <a href="https://tristanbonal.com" target="_blank">Tristan Bonal</a>
            </li>
          </ul>
        </div>

        <div class="footer-col">
          <h4>{{ $t("footer.company") }}</h4>
          <ul>
            <li>
              <router-link to="/">{{ $t("menus.0") }}</router-link>
            </li>
            <li>
              <router-link to="/services">{{ $t("menus.1") }}</router-link>
            </li>
            <li>
              <router-link to="/contact">{{ $t("menus.2") }}</router-link>
            </li>
          </ul>
        </div>

        <div class="footer-col">
          <h4>{{ $t("footer.social") }}</h4>
          <div class="social-links">
            <a href="https://www.linkedin.com/company/kortle" target="_blank">
              <i class="fab fa-linkedin-in"></i>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61563583419694" target="_blank">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/_kortle/" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@_kortle" target="_blank">
              <i class="fab fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p>&copy;2024 Kortle. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer {
  background-color: #24262b;
  padding-top: 70px;
  padding-left: 15px;
  padding-right: 15px;
}

.container {
  max-width: 1170px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-col {
  width: 25%;
  padding: 0 15px;
}

ul {
  list-style: none;
}

.footer-col h4 {
  display: inline-block;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer-col h4::before {
  content: "";
  position: absolute;
  bottom: -10px;
  background: linear-gradient(to right, #3533cd, #a612c4);
  height: 2px;
  box-sizing: border-box;
  width: 100%;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}

.social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 5px 10px 5px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}

.social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}

.copyright {
  text-align: center;
  padding: 20px 0;
  border-top: #636262 solid 1px;
  color: #ffffff;
  margin-top: 30px;
}

.copyright p {
  margin: 0;
  font-size: 14px;
}
</style>
