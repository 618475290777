<template>
  <div
    class="card relative border-0 p-4"
    :class="{
      'bg-black text-white': invertColors,
      'bg-white text-black': !invertColors,
    }"
  >
    <div class="p-4 flex flex-col items-center text-center">
      <!-- Titre avec icône -->
      <div
        id="title-container"
        class="flex items-center justify-center absolute z-50 pr-4"
        :class="{ 'bg-black': invertColors, 'bg-white': !invertColors }"
      >
        <img
          class="relative"
          :src="icon"
          :alt="title"
          height="60px"
          width="60px"
          style="left: -2px"
        />
        <h2
          class="text-2xl font-bold text-center"
          :class="{ 'text-white': invertColors, 'text-black': !invertColors }"
          style="flex-grow: 1; text-align: center"
        >
          {{ title }}
        </h2>
      </div>

      <!-- Description -->
      <p
        class="text-18 my-2 flex items-center"
        :class="{
          'text-gray-400': invertColors,
          'text-gray-600': !invertColors,
        }"
        id="description"
      >
        {{ description }}
      </p>

      <!-- Prix -->
      <h3 class="text-sm font-semibold mb-2">
        <span class="text-xl">{{ $t("services.card.from") }}</span>
        <!-- Agrandissement du "dès" -->
        <span id="price" class="text-5xl font-bold mb-2">
          <!-- Agrandissement du prix -->
          {{ price }} € <span class="text-2xl">TTC</span>
        </span>
      </h3>
      <button
        id="modal"
        @click="openModal"
        class="rounded-full px-8 py-2 font-medium mt-4 mb-4"
        :class="{
          'bg-white text-black': invertColors,
          'bg-black text-white': !invertColors,
        }"
      >
        {{ $t("services.card.estimate") }}
      </button>
      <ModalEstimation
        :isOpen="isModalOpen"
        :service="serviceType"
        @close="closeModal"
      >
      </ModalEstimation>
      <p
        class="text-sm font-semibold"
        :class="{
          'text-gray-400': invertColors,
          'text-gray-500': !invertColors,
        }"
      >
        {{ $t("services.card.pay") }}
      </p>
      <div id="delivery" class="flex mt-2">
        <img id="chrono" :src="invertColors ? countdown : blackCountdown" />
        <p v-if="serviceType === 10 || serviceType === 11"
           class="ml-2 font-semibold text-left flex items-center text-xl pt-1">
          {{ $t("services.card.delivery") }}
        </p>
        <p v-if="serviceType === 20 || serviceType === 21 || serviceType === 30 || serviceType === 31"
           class="ml-2 font-semibold text-left flex items-center text-xl pt-1">
          {{ $t("services.card.delivery2") }}
        </p>
      </div>
      <!-- Ligne de séparation -->
      <div
        class="my-4 w-full border"
        :class="{ 'border-white': invertColors, 'border-black': !invertColors }"
      />

      <!-- Liste des fonctionnalités -->
      <ul
        class="text-base text-left space-y-2 leading-8"
        :class="{ 'text-white': invertColors, 'text-gray-700': !invertColors }"
      >
        <li
          v-for="(item, index) in features"
          :key="index"
          class="flex items-center"
          :class="{ 'text-white': invertColors, 'text-black': !invertColors }"
        >
          <img
            :src="
              item.included
                ? 'https://cdn-icons-png.flaticon.com/512/13903/13903585.png'
                : 'https://cdn-icons-png.flaticon.com/512/7817/7817044.png'
            "
            :alt="item.included ? 'Inclus' : 'Non inclus'"
            class="w-6 h-6 mr-2"
          />
          {{ item.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ModalEstimation from "@/components/Services/ModalEstimation.vue";
import countdown from "@/assets/icon/chronomètre.png";
import blackCountdown from "@/assets/icon/chronomètre-noir.png";

export default {
  name: "ServiceCard",
  components: {
    ModalEstimation,
  },
  data() {
    return {
      isModalOpen: false,
      countdown,
      blackCountdown,
    };
  },
  props: {
    serviceType: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    features: {
      type: Array,
      required: true,
      validator(value) {
        return value.every(
          (item) =>
            typeof item.text === "string" && typeof item.included === "boolean"
        );
      },
    },
    invertColors: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
      document.body.style.overflowY = "hidden";
    },
    closeModal() {
      this.isModalOpen = false;
      document.body.style.overflowY = "auto";
    },
  },
};
</script>

<style scoped>
.card {
  position: relative;
  height: max-content;
  width: 400px;
  padding: 1rem;
  border: 0px solid #fff !important;
  margin-top: 1rem;
}

.card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 22px; /* Même radius que la carte */
  padding: 4px; /* Épaisseur de la bordure */
  background: linear-gradient(135deg, #2799ec, #f65aff);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none; /* S'assurer que l'effet ne bloque pas l'interaction */
  z-index: 0;
  overflow: hidden;
}

.card h2 {
  font-size: 2rem;
  flex-grow: 1;
  text-align: center;
  margin-left: 1rem;
}

.card p {
  font-family: "Familjen Grotesk", sans-serif;
}

.card ul li {
  font-size: 1rem;
  font-family: "Lora", serif;
}

.card button {
  width: 100%;
  padding: 0.5rem 1rem;
}

.text-18 {
  font-size: 18px;
}

#title-container {
  top: -30px;
  right: 100px;
}

#delivery {
  width: 100%;
}

#chrono {
  height: 31px;
  width: 26px;
}

#modal {
  transition: background-image 0.5s ease, color 0.5s ease, transform 0.3s;
}

#modal:hover {
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
  color: #fff;
  transform: scale(1.05);
}
#description {
  height: 54px;
}

#description,
button {
  font-family: "Familjen Grotesk", sans-serif;
}

#price {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
}

@media all and (max-width: 680px) {
  .card:first-child {
    margin-top: 8rem;
  }
}

@media all and (max-width: 490px) {
  .card {
    width: 100%;
  }
  ul {
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  li {
    line-height: 1;
  }
}

@media all and (max-width: 460px) {
  .card {
    margin-top: 10rem !important;
  }
}

@media all and (max-width: 440px) {
  .card {
    margin-bottom: 5rem;
  }
  .card div:first-of-type {
    padding: 0rem;
  }

  #title-container {
    right: unset;
  }
  #description {
    height: auto;
  }

  #chrono {
    margin-block: auto;
  }
}
@media all and (max-width: 380px) {
  #description {
    margin-block: 20px;
  }
}
@media all and (max-width: 340px) {
  #title-container {
    top: -20px;
  }
  #title-container img {
    height: 45px;
    width: 45px;
  }
  #title-container h2 {
    font-size: 1.5rem;
  }
}

@media all and (max-width: 320px) {
  #description {
    margin-block: 30px;
  }
}

@media all and (max-width: 310px) {
  ul {
    height: auto;
  }
}
@media all and (max-width: 270px) {
  #description {
    margin-block: 40px;
  }

  #title-container {
    flex-direction: column;
    align-items: center;
  }

  #title-container h2 {
    margin-left: 0rem;
  }

  #delivery {
    flex-direction: column;
    align-items: center;
  }
  #delivery p {
    text-align: center;
    font-size: 1rem;
  }
  .card ul li {
    font-size: 0.7rem;
  }
}
</style>
