<template>
  <teleport to="body">
    <div v-if="isOpen" class="modal-overlay" @click="closeModal">
      <div class="modal-content text-white" @click.stop>
        <div class="modal-header">
          <div class="flex flex-row items-center">
            <img
                :src="icon"
                alt="Construction / Reconstruction"
                height="60px"
                width="60px"
            />
            <h3 class="text-lg ml-2 px-3.5">
              {{ serviceName }} | {{ $t("services.modal.title") }}
            </h3>
          </div>
          <button class="close-button" @click="closeModal">
            <i class="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>

        <div class="modal-body">
          <!-- Option selectors (displayed only if the price is not yet estimated) -->
          <div v-if="!isEstimated">
            <!-- Site type -->
            <div
                v-if="service === 10 || service === 11"
                class="options-selector"
            >
              <div class="flex items-center relative">
                <img :src="website" alt="Site web" class="icon"/>
                <div class="flex flex-col">
                  <label for="siteType">
                    {{ $t("services.modal.options.siteTypeLabel") }} :
                  </label>
                  <select
                      id="siteType"
                      v-model="selectedSiteType"
                      @change="updatePrice"
                      class="inline-select w-36 text-black"
                  >
                    <option value="vitrine">
                      {{ $t("services.modal.options.siteTypes.vitrine") }}
                    </option>
                    <option value="blog">
                      {{ $t("services.modal.options.siteTypes.blog") }}
                    </option>
                    <option value="reservation">
                      {{ $t("services.modal.options.siteTypes.reservation") }}
                    </option>
                    <option value="ecommerce">
                      {{ $t("services.modal.options.siteTypes.ecommerce") }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Selector for the number of pages (websites) or views (mobile apps) -->
            <div
                v-if="
                service === 10 ||
                service === 11 ||
                service === 20 ||
                service === 21
              "
                class="options-selector"
            >
              <div class="flex items-center relative">
                <img :src="pages" alt="Site web" class="icon"/>
                <div class="flex flex-col">
                  <label for="pageOrViewCount">
                    {{
                      service === 10 || service === 11
                          ? $t("services.modal.options.pageCount")
                          : $t("services.modal.options.viewCount")
                    }} :
                  </label>
                  <select
                      id="pageOrViewCount"
                      class="w-36 text-black"
                      v-model="selectedPageOrViewCount"
                      @change="updatePrice"
                  >
                    <option value="inf3">
                      {{ $t("services.modal.options.pageOrViewCount.inf3") }}
                    </option>
                    <option value="3-5">
                      {{ $t("services.modal.options.pageOrViewCount.3-5") }}
                    </option>
                    <option value="sup5">
                      {{ $t("services.modal.options.pageOrViewCount.sup5") }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Delivery time -->
            <div class="options-selector">
              <div class="flex items-center relative">
                <img :src="calendrier" alt="Site web" class="icon"/>
                <div class="flex flex-col">
                  <label for="delay">
                    {{ $t("services.modal.options.delayLabel") }} :
                    <span class="tooltip">
                      <i class="fas fa-info-circle"></i>
                      <span class="tooltip-content">
                        {{ $t("services.modal.options.delayTooltip") }}
                      </span>
                    </span>
                  </label>

                  <select
                      id="delay"
                      class="w-36 text-black"
                      v-model="selectedDelay"
                      @change="updatePrice"
                  >
                    <option value="sup6mois">
                      {{ $t("services.modal.options.delayOptions.sup6mois") }}
                    </option>
                    <option value="3-6mois">
                      {{ $t("services.modal.options.delayOptions.3-6mois") }}
                    </option>
                    <option value="1-3mois">
                      {{ $t("services.modal.options.delayOptions.1-3mois") }}
                    </option>
                    <option value="inf1mois">
                      {{ $t("services.modal.options.delayOptions.inf1mois") }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Design expectations selector -->
            <div class="options-selector">
              <div class="flex items-center relative">
                <img :src="design" alt="Site web" class="icon"/>
                <div class="flex flex-col">
                  <label for="design">
                    {{ $t("services.modal.options.designLabel") }} ?
                  </label>
                  <select
                      id="design"
                      class="w-36 text-black"
                      v-model="selectedDesign"
                      @change="updatePrice"
                  >
                    <option value="maquette">
                      {{ $t("services.modal.options.designOptions.maquette") }}
                    </option>
                    <option value="charte">
                      {{ $t("services.modal.options.designOptions.charte") }}
                    </option>
                    <option value="carteblanche">
                      {{ $t("services.modal.options.designOptions.carteblanche") }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="checkbox">
              <!-- Translation selector -->
              <div class="options-checkbox">
                <label for="translation">
                  {{ $t("services.modal.options.translationCheckbox") }} :
                </label>
                <div class="checkbox-wrapper-2">
                  <input
                      type="checkbox"
                      class="sc-gJwTLC ikxBAC"
                      v-model="isTranslationNeeded"
                      @change="updatePrice"
                  />
                </div>
              </div>

              <!-- Dark Mode option -->
              <div class="options-checkbox">
                <label for="darkMode">
                  {{ $t("services.modal.options.darkModeCheckbox") }} :
                </label>
                <div class="checkbox-wrapper-2">
                  <input
                      type="checkbox"
                      class="sc-gJwTLC ikxBAC"
                      v-model="isDarkModeEnabled"
                      @change="updatePrice"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Display "Estimate" button if the price is not yet estimated -->
          <div class="modal-footer" v-if="!isEstimated">
            <button
                @click="estimatePrice"
                class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              {{ $t("services.modal.estimateButton") }}
            </button>
          </div>

          <!-- Display estimated price -->
          <EstimatedPrice
              v-if="isEstimated"
              :estimatedPrice="estimatedPrice"
              :label="$t('services.modal.estimatedPrice')"
              :service="service"
              :selectedSiteType="selectedSiteType"
              :selectedPageOrViewCount="selectedPageOrViewCount"
              :selectedDelay="selectedDelay"
              :selectedDesign="selectedDesign"
              :isTranslationNeeded="isTranslationNeeded"
              :isDarkModeEnabled="isDarkModeEnabled"
              @save="handleSave"
          />
        </div>
      </div>
    </div>
    <SnackBar
        v-if="showSnackbar"
        :message="$t('services.modal.saved')"
        :duration="3000"
    />
  </teleport>
</template>

<script>
import iconPaintbrush from "@/assets/icon/maintenance.png";
import iconConstruction from "@/assets/icon/travaux-routiers.png";
import website from "@/assets/icon/website.png";
import pages from "@/assets/icon/pages.png";
import calendrier from "@/assets/icon/calendrier.png";
import design from "@/assets/icon/design.png";

import EstimatedPrice from "@/components/Services/EstimatedPrice.vue";
import SnackBar from "@/components/Utils/SnackBar.vue";

export default {
  name: "ModalEstimation",
  components: {SnackBar, EstimatedPrice},
  props: {
    service: {
      type: Number,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconPaintbrush,
      iconConstruction,
      website,
      pages,
      design,
      calendrier,
      icon: "",
      serviceName: "",

      selectedSiteType: "vitrine", // Valeur par défaut
      selectedPageOrViewCount: "inf3", // Valeur par défaut pour le nombre de pages/vues
      selectedDelay: "sup6mois", // Valeur par défaut pour le délai
      selectedDesign: "maquette", // Valeur par défaut pour le design

      basePrice: 0, // Prix de base selon le service
      estimatedPrice: 0, // Prix estimé final
      siteTypeCost: 0, // Coût lié au type de site
      pageOrViewCost: 0, // Coût lié au nombre de pages ou de vues
      delayCost: 0, // Coût lié au délai de livraison
      translationCost: 0, // Coût lié à la traduction
      designCost: 0, // Coût lié au design
      darkModeCost: 0, // Coût lié au Dark Mode

      isTranslationNeeded: false, // Booléen pour la traduction
      isDarkModeEnabled: false, // Booléen pour le Dark Mode
      isEstimated: false, // État de l'estimation

      showSnackbar: false,
    };
  },
  methods: {
    handleSave() {
      this.closeModal();
      this.showSuccessSnackbar(); // Affiche la snackbar de succès après la fermeture de la modal
    },
    closeModal() {
      this.$emit("close");
    },
    showSuccessSnackbar() {
      this.showSnackbar = true;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 3000); // La snackbar disparaît après 3 secondes
    },
    setInitialPrice() {
      switch (this.service) {
        case 10:
          this.icon = iconConstruction;
          this.serviceName = this.$t("services.modal.serviceName.web");
          this.basePrice = 1500;
          break;
        case 11:
          this.icon = iconPaintbrush;
          this.serviceName = this.$t("services.modal.serviceName.web");
          this.basePrice = 1200;
          break;
        case 20:
          this.icon = iconConstruction;
          this.serviceName = this.$t("services.modal.serviceName.mobile");
          this.basePrice = 2000;
          break;
        case 21:
          this.icon = iconPaintbrush;
          this.serviceName = this.$t("services.modal.serviceName.mobile");
          this.basePrice = 1500;
          break;
        case 30:
          this.icon = iconConstruction;
          this.serviceName = this.$t("services.modal.serviceName.software");
          this.basePrice = 2000;
          break;
        case 31:
          this.icon = iconPaintbrush;
          this.serviceName = this.$t("services.modal.serviceName.software");
          this.basePrice = 1500;
          break;
        default:
          this.basePrice = 0;
          break;
      }
      this.estimatedPrice = this.basePrice; // Initialisation du prix estimé
      this.updatePrice(); // Mise à jour initiale du prix
    },
    estimatePrice() {
      this.updatePrice(); // Recalculer le prix estimé
      this.isEstimated = true; // Met à jour l'état pour afficher le prix
    },
    updatePrice() {
      // Réinitialiser les coûts
      this.siteTypeCost = 0;
      this.pageOrViewCost = 0;
      this.delayCost = 0;
      this.designCost = 0;
      this.translationCost = 0;
      this.darkModeCost = 0;

      this.updateDelay();
      this.updateSiteType();
      this.updateDesign();
      this.updateNbViews();
      this.updateTranslation();
      this.updateDarkMode();

      // Mise à jour du prix estimé
      this.estimatedPrice =
          this.basePrice +
          this.delayCost +
          this.siteTypeCost +
          this.translationCost +
          this.designCost +
          this.pageOrViewCost +
          this.darkModeCost;
    },

    updateDelay() {
      switch (this.selectedDelay) {
        case "inf1mois":
          this.delayCost = 500;
          break;
        case "1-3mois":
          this.delayCost = 250;
          break;
        case "3-6mois":
          this.delayCost = 100;
          break;
        default:
          // Supérieur à 6 mois n'ajoute rien
          break;
      }
    },

    updateSiteType() {
      switch (this.selectedSiteType) {
        case "ecommerce":
          this.siteTypeCost = 1000;
          break;
        case "reservation":
          this.siteTypeCost = 800;
          break;
        case "blog":
          this.siteTypeCost = 500;
          break;
        case "vitrine":
          this.siteTypeCost = 0;
          break;
      }
    },

    updateDesign() {
      switch (this.selectedDesign) {
        case "carteblanche":
          this.designCost = 150;
          break;
        case "charte":
          this.designCost = 50;
          break;
        default:
          // Si l'utilisateur a déjà une maquette, pas de coût additionnel
          break;
      }
    },

    updateNbViews() {
      let multplicateur;
      switch (this.selectedPageOrViewCount) {
        case "inf3":
          multplicateur = 1;
          break;
        case "3-5":
          multplicateur = 2;
          break;
        case "sup5":
          multplicateur = 3;
          break;
      }

      let coefficient;
      switch (this.service) {
        case 10:
        case 11:
          coefficient = 150;
          break;
        case 20:
        case 21:
          coefficient = 400;
          break;
        default:
          coefficient = 0;
          break;
      }
      this.pageOrViewCost = multplicateur * coefficient;
    },

    updateTranslation() {
      this.translationCost = this.isTranslationNeeded ? 200 : 0;
    },

    updateDarkMode() {
      this.darkModeCost = this.isDarkModeEnabled ? 300 : 0;
    },
  },
  mounted() {
    this.setInitialPrice();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  font-family: "Poppins", sans-serif;
  padding: 20px;
  border-radius: 18px;
  width: 55%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(#444444, #36393e) padding-box,
  linear-gradient(135deg, #2799ec, #f65aff) border-box;
  border: 4px solid transparent;
  padding-right: 12px;
}

.modal-content::-webkit-scrollbar {
  width: 12px;
}

.modal-content::-webkit-scrollbar-track {
  background: #000;
  border-radius: 18px;
  margin-block: 6px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #000;
  margin-right: 4px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(135deg, #2799ec, #f65aff);
  border-image-slice: 1;
  padding-bottom: 10px;
}

.modal-body {
  margin-top: 20px;
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.close-button:hover {
  color: #e74c3c;
}

.options-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.options-selector div {
  right: 2.25rem;
  width: 370px;
  margin-left: 2rem;
}

.checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options-checkbox {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.options-selector label,
.options-checkbox label {
  margin-right: 10px;
  font-weight: bold;
  margin-bottom: 7px;
  font-size: 20px;
}

.options-selector label {
  width: 100%;
}

.options-selector select {
  width: 215px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltip-content {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Position above the element */
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-content,
.tooltip:active .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.icon {
  height: 60px;
  width: 60px;
  border-radius: 100%;
}

/* Chexbox style */
.checkbox-wrapper-2 .ikxBAC {
  appearance: none;
  background-color: #fff;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 40px;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #000;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 input[type="checkbox"] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-image: linear-gradient(135deg, #2799ec, #f65aff);
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 22px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: #535db3;
}

@media all and (max-width: 1000px) {
  .modal-content {
    width: 75%;
  }
}

@media all and (max-height: 790px) {
  .modal-content::after {
    display: none;
  }
}

/* Adjust for mobile */
@media (max-width: 768px) {
  .tooltip .tooltip-content {
    width: 150px;
  }
}

@media all and (max-width: 630px) {
  .modal-content {
    width: 90%;
  }
}

@media all and (max-width: 565px) {
  .modal-footer {
    margin-top: 1rem;
  }

  .options-checkbox {
    margin-top: 0px;
  }

  .options-checkbox label {
    margin-bottom: 0px;
    font-size: 16px;
  }

  .options-selector {
    margin-bottom: 10px;
  }

  .options-selector div {
    flex-direction: column;
    width: 100%;
    right: 0rem;
    text-align: center;
    margin-left: 0rem;
  }

  .options-selector select {
    height: 34px;
  }

  .options-selector label {
    font-size: 16px;
    margin-right: 0px;
  }

  .options-selector div div {
    align-items: center;
  }

  .icon {
    height: 45px;
    width: 45px;
  }
}

@media all and (max-width: 450px) {
  .options-checkbox {
    width: 100%;
  }

  .modal-content {
    width: 95%;
  }
}
</style>
